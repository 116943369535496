<template>
  <div class="notice-wrap">
    <div class="marquee" style="margin-right:0.5rem;margin-left:0.5rem;width: calc(100% - 1rem);">
      <div class="title" :class="{ 'marquee-txt': isScroll, 'jucontent-center': !isScroll }" ref="marqueeRef"
        v-html="isExistUser ? (getUserName() + timeLeft) : timeLeft"></div>
    </div>
    <Popup showTime="10" v-if="isOpen" @closePop="closePop">{{ errMessage }}</Popup>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import moment from 'moment'
import Popup from '@/views/common/popup'
import useEndTime from '@/hooks/useEndTime'
import { useRouter, useRoute } from 'vue-router'
import { cancelBooking, logout, cancelBookingForProgramme } from '@/http/api'
import { nextTick, onMounted, ref, watch } from '@vue/runtime-core'
import { formatName } from '@/util/util'
import { useStore } from 'vuex';
export default {
  name: 'commonnotice-component',
  components: {
    Popup
  },
  props: {
    message: {
      type: String,
      default: 'payCountdown'
    },
    deadline: {
      type: [Number, String],
      default: () => {
        return localStorage.getItem('logoutTime') || 600 // 默认倒计时600秒
      }
    },
    img: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const isOpen = ref(false)
    const errMessage = ref('')
    const route = useRoute()
    const marqueeRef = ref(null)
    const distance = ref('0')
    onMounted(() => {
      formatNameFunc()
      setScroll()
    })
    const router = useRouter()
    const store = useStore();
    let curUsername = ref('')
    let isExistUser = ref(false)
    let isScroll = ref(false)
    const { t } = useI18n();
    console.log('route', route.name)

    const logoutAPI = () => {
      logout().then((res) => {
        if (res && res.code && res.code != 0) {
          isOpen.value = true
          errMessage.value = res.message || t('serverErr')
          return
        }
        localStorage.removeItem('token')
      }).finally(() => {
        router.push({
          name: 'home'
        })
      })
    }
    // 预订时间超时
    const endTimeEventFun = () => {
      if (route.name === 'confirmBooking') {
        let applicationId = sessionStorage.getItem('facility_application_id')
        cancelBooking(applicationId).then(res => {
          console.log(res)
        }).finally(() => {
          sessionStorage.removeItem('facility_application_id')
          logoutAPI()
        })
      } else if (route.name === 'confirmProgrammeBooking') {
        let _params = {
          applicationId: sessionStorage.getItem('programme_application_id')
        }
        cancelBookingForProgramme(_params).then(res => {
          console.log(res)
        }).finally(() => {
          sessionStorage.removeItem('programme_application_id')
          logoutAPI()
        })
      } else {
        logoutAPI()
      }
    }
    const formatNameFunc = () => {
      if (sessionStorage.getItem("userinfo")) {
        let userInfo = JSON.parse(sessionStorage.getItem("userinfo"))
        curUsername.value = formatName(localStorage.getItem('language'), userInfo)
        isExistUser.value = true
      } else {
        curUsername.value = ''
        isExistUser.value = false
      }
    }

    const setScroll = () => {
      if (marqueeRef.value) {
        nextTick(() => {
          distance.value = (- marqueeRef.value.scrollWidth / marqueeRef.value.clientWidth * 100) + '%'
          if (marqueeRef.value.scrollWidth > marqueeRef.value.clientWidth) {
            isScroll.value = true
          } else {
            isScroll.value = false
          }
        })
      }
    }

    const getUserName = () => {
      return t('hi_username', { username: curUsername.value })
    }

    const params = {
      langKey: props.message,
      paramKey: 'num',
      seconds: props.deadline,
      type: 'commonNotice',
      isFormat: true, // 是否按照 时:分:秒 的格式输出时间
      // isDelay: true,
      endTimeEvent() {
        endTimeEventFun()
        // logoutAPI()
      },
      reminderEvent(tmpTime) {
        isOpen.value = true
        errMessage.value = t('reminder_time', { remderTime: parseInt(tmpTime.split(':')[0]) })
      }
    }

    const closePop = () => {
      isOpen.value = false
    }

    let { timeLeft } = useEndTime(params)

    watch(timeLeft, () => {
      setScroll()
    })

    watch(store.state.userInfo, () => {
      formatNameFunc()
    })

    return {
      timeLeft,
      isOpen,
      errMessage,
      logoutAPI,
      endTimeEventFun,
      closePop,
      getUserName,
      isExistUser,
      isScroll,
      marqueeRef,
      distance
    }
  },
  methods: {
    moment
  }
}
</script>

<style lang="scss" scoped>
$pxtoRem: 100;

@function pxttRem($px) {
  @return calc($px / $pxtoRem)+rem;
}

.notice-wrap {
  font-family: 'Barlow';
  width: 100%;
  position: fixed;
  top: 0rem !important;
  z-index: 21;
  background: linear-gradient(73deg, #8F0044 11.91%, #BC003C 37.3%, #E20030 63.49%, #FF1636 88.09%);
  height: pxttRem(70);
  line-height: pxttRem(70);
  justify-content: center;
  display: flex;

  .title {
    @include color-font('color3');
    text-align: center;
    font-size: pxttRem(24);
    display: flex;

    :deep span {
      font-size: 32px;
      padding: 0 8px;
      font-weight: 600;
    }
  }
}

.jucontent-center {
  justify-content: center;
}

/* 文字滚动 */
.marquee {
  box-sizing: border-box;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}

.marquee-txt {
  display: inline-block;
  /* 从右至左开始滚动 */
  animation: marqueeTransform 16s linear infinite;

  &:hover {
    //鼠标悬停 停止横向滚动
    animation-play-state: paused;
  }
}

@keyframes marqueeTransform {
  0% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(v-bind(distance), 0);
  }
}
</style>
