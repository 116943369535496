<template>
    <div class="footer" v-show="!hideFooter">
        <div class="left">
            <!-- <img src="@/assets/image/icon_contact.png" class="icon" />
            <div class="phone-number">{{ $t('contact_us')}} {{ systemInfo.hotLine }}</div> -->
        </div>
        <div class="right">
            <img src="@/assets/image/ic_en.svg" class="icon" @click="changeLang('en')" v-if="curLang!='en'"/>
            <img src="@/assets/image/ic_tc.svg" class="icon" @click="changeLang('zh-hk')" v-if="curLang!='zh-hk'"/>
            <img src="@/assets/image/ic_sc.svg" class="icon" @click="changeLang('zh-cn')" v-if="curLang!='zh-cn'"/>
            <img src="@/assets/image/ic_screen_down.svg" class="mode" v-show="!isSpecialMode && !isHideIcon" />
            <img src="@/assets/image/ic_screen_up.svg" class="mode" v-show="isSpecialMode && !isHideIcon" />
            <a-switch v-show="!isHideIcon" v-model:checked="isSpecialMode" @change="changeMode(isSpecialMode)" />
        </div>
    </div>
</template>

<script>
import useFooter from '@/hooks/useFooter'
import useLang from '@/hooks/useLang'
import { inject, watch, getCurrentInstance, nextTick } from '@vue/runtime-core';
import { getLanguage,setLanguage } from "@/util/authentication";
import { computed, ref } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import i18n from "@/i18n"
import { useStore } from 'vuex';

export default {
    setup() {
        const Bus = getCurrentInstance().appContext.config.globalProperties.$bus;
        let isSpecialMode = inject('isSpecialMode');
        let forceUpdate = inject('forceUpdate');
        let { hideFooter } = useFooter(isSpecialMode);
        let changeMode = inject('changeMode');
        let curLang = ref('');
        let router = useRouter();
        curLang.value = getLanguage();
        const store = useStore();

        watch(() => store.state.currentLanguage, (newValue) => {
            curLang.value = getLanguage();
        })
        watch(
            () => hideFooter.value,
            (val,oldval) => {
                curLang.value = getLanguage();
            },
            { immediate: true }
        );
        Bus.on('refresh', () => {
          curLang.value = getLanguage();
        });
        const changeLang = (lang) => {
            useLang();
            nextTick(() => {
                setLanguage(lang, store);
            })
            curLang.value = lang;
            // window.location.reload();
            const { locale } = i18n.global;
            locale.value = lang;
            forceUpdate();
        }

        const isHideIcon = computed(() => {
            // return router.currentRoute.value.name === 'selectSport' || router.currentRoute.value.name === 'ballotSelect';
            return false;
        })

        const systemInfo = inject('systemInfo');

        return {
            hideFooter,
            changeLang,
            changeMode,
            isSpecialMode,
            curLang,
            isHideIcon,
            systemInfo
        }
    }
}
</script>

<style lang="scss" scoped>
    .footer {
        z-index: 100;
        position: fixed;
        display: flex;
        align-items: center;
        bottom: 0;
        height: 72px;
        width: 100%;
        padding: 16px 32px;
        @include footer-bg('color-court-bg');
        .left {
            display: flex;
            align-items: center;
            flex-grow: 1;
            margin-left: 0.4rem;
            .icon {
                height: 0.4rem;
                width: 0.4rem;
                margin-right: 0.1rem;
            }
            .phone-number {
                font-size: 0.28rem;
                @include text-color('color3');
            }
        }
        .right {
            .icon {
                height: 40px;
                width: 40px;
                margin-right: 24px;
            }
            .mode {
                height: 40px;
                width: 40px;
                margin-right: 4px;
            }
            /* 设置Switch的默认大小 */
            .ant-switch {
                width: 80px;  /* 设置Switch的宽度 */
                height: 40px; /* 设置Switch的高度 */
            }
            /* 设置Switch在checked状态下的大小 */
            .ant-switch-checked {
                width: 80px;  /* 设置Switch在checked状态下的宽度 */
                height: 40px; /* 设置Switch在checked状态下的高度 */
                ::v-deep .ant-switch-handle{
                    left: calc(100% - 35px) !important;
                }
            }
            ::v-deep .ant-switch-handle{
                top: 5px;
                left: 5px;
                width: 30px;
                height: 30px;
            }
            ::v-deep .ant-switch-handle::before{
                border-radius: 15px;
            }
            .ant-switch-checked{
                background-color: #EC0032 !important;
            }
            .ant-switch{
                background-color: #B1B1B1;
            }
        }
    }
</style>