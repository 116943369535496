<template>
    <div class="ad-list">
        <img
            v-for="ad in adList"
            :key="ad.id"
            :src="ad.imageUrl"
            :class="['pic', { show: ad.isShow }]"
        />
    </div>
</template>

<script>
import { ref, nextTick, onUnmounted, watch, inject } from 'vue';
import { getAd, getSystemInfo, getImgStreams } from "@/http/api";
import { useRouter } from 'vue-router';
export default {
    name: "ad-list",
    setup() {
        const router = useRouter();
        let interval = null;
        let start = 0;
        const adList = ref([]);
        const getAdData = () => {
            getAd('HOME')
                .then((res) => {
                    if (Array.isArray(res) && res[0]) {
                        adList.value.length = 0;
                        res.forEach((item, idx) => {
                            // 默认展示第一张
                            if (idx === 0) {
                                item.isShow = true;
                            } else {
                                item.isShow = false;
                            }
                        });
                        adList.value = res;
                        let imges = []
                        res.map(it => {
                            if (it.srcCode) {
                                imges.push(it.srcCode)
                            }
                        })
                        let a = [...new Set(imges)]
                        getgetImgStreams(a)
                        // 执行广告轮播
                        adRotation();
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    // to do
                    // adList.value.unshift({
                    //     url: "https://img1.baidu.com/it/u=1086671070,3883554294&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=888",
                    //     // isShow: true,
                    // });
                    // adList.value.unshift({
                    //     srcCode: "https://img2.baidu.com/it/u=2893076445,3693376543&fm=253&fmt=auto&app=120&f=JPEG?w=658&h=931",
                    //     isShow: true,
                    // });
                });
        };
        // 获取图片链接的
        function getgetImgStreams(img) {
            var result = img.join(",")
            console.log(result, 'result')
            let param = {
                fileId: result
            }
            getImgStreams(param, false).then(res => {
                const arrs = adList.value.map(item => {
                const data = res.find(i => {
                    if (item.srcCode == i.id) {
                    item.imageUrl = i.url
                    }
                })
                return {
                    ...item,
                    ...data
                }
                })
                adList.value = arrs
            })
        }
        function adRotation() {
            if (!getSystemInfoDone || interval) {
                return;
            }
            // console.log(adList, systemInfo.value.intervalTime, '---------');
            nextTick(() => {
                if (adList.value.length > 1) {
                    let intervalTime = systemInfo.value.intervalTime || 30;
                    interval = setInterval(() => {
                        // console.log("广告切换！！！", systemInfo.value.intervalTime);
                        if (adList.value.length === start + 1) {
                            start = 0;
                        } else {
                            start++;
                        }
                        adList.value.forEach((item, idx) => {
                            if (idx === start) {
                                item.isShow = true;
                            } else {
                                item.isShow = false;
                            }
                        });
                    }, intervalTime * 1000);
                }
            });
        }

        const setSystemInfo = inject('setSystemInfo');
        const systemInfo = inject('systemInfo');
        // 获取kiosk系统信息 客服热线 广告轮播间隔 无操作返回首页时间
        let getSystemInfoDone = false;
        const getSysInfo = () => {
            getSystemInfo().then(res => {
                let sysInfo = {};
                if (Array.isArray(res)) {
                    res.forEach(item => {
                        if (item.paramCode === 'CUSTOMER_SERVICE_HOTLINE') {
                            sysInfo.hotLine = item.paramValue;
                        }
                        if (item.paramCode === 'KIOSK_AD_INTERVAL_TIME') {
                            sysInfo.intervalTime = item.paramValue;
                        }
                        if (item.paramCode === 'LONG_TIME_NO_OPERATION_LOGOUT') {
                            sysInfo.logoutTime = item.paramValue;
                        }
                    })
                    setSystemInfo(sysInfo);
                }
            }).finally(() => {
                getSystemInfoDone = true;
                // 执行广告轮播
                adRotation();
            })
        }

        watch(
            () => router.currentRoute.value,
            (n, o) => {
                if (n.name == 'home'||n.name == 'menu') {
                    if ((o && o.name == 'home')||(o && o.name == 'menu')) {
                        return;
                    }
                    getSystemInfoDone = false;
                    getSysInfo();
                    getAdData();
                } else {
                    clearInterval(interval);
                    interval = null;
                }
            },
            { immediate: true }
        );
        
        onUnmounted(() => {
            clearInterval(interval);
            interval = null;
        });
        return { adList }
    },
};
</script>

<style scoped lang="scss">
.ad-list {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    // @include color-bg-primary("color");
    .pic {
        object-fit: cover;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        transition: all 1s ease 0s;
        opacity: 0;
        &.show {
            opacity: 1;
        }
    }
}
</style>