import { createI18n } from "vue-i18n";
import en from "./lang/en";
import zh_CN from "./lang/zh_CN";
import zh_HK from "./lang/zh_HK";
import { getLanguage, setLanguage } from "@/util/authentication";

if (["en", "zh-hk", "zh-cn"].indexOf(getLanguage()) < 0) {
  // 默认语言是英语
  setLanguage("en");
  // setLanguage("zh-hk");
}

const i18n = createI18n({
  locale: getLanguage(),
  legacy: false, // vue3.0中  使用组合式API时必须设置为false
  warnHtmlMessage: false, // 国际化使用v-html插入时 会有xss攻击提示， 这里关闭
  messages: {
    "zh-cn": zh_CN,
    "zh-hk": zh_HK,
    "en": en,
  },
});

export default i18n;
