import {deleteA, get, post, put, requestLocalhost} from "./http";

export const getSystemInfo = () => {
    return get("/rest/param/api/v1/publ/com-params/kiosk_system");
};

// 场馆页查询运动类型
export const getAllSports = (params) => {
    // return get("/rest/facility-catalog/api/v1/publ/facility-params/facilities");
    return get(
        "/rest/facility-catalog/api/v1/publ/facility-params/fat-list",
        params
    );
};

// 课程页查询运动类型
export const getSportTypeInProgramme = () => {
    return get(
        "/rest/param/api/v1/publ/enums/activity_type/grouping-by/sec/sports_type"
    );
};

// 场馆页查询场馆信息
export const getVenues = (params) => {
    return get(
        "/rest/facility-catalog/api/v1/publ/facility-params/venues",
        params
    );
};

// 查询场馆可以使用时间
export const getUseTime = (params) => {
    return get("/rest/facility-catalog/api/v1/publ/facilities", params);
};

//查询facility booking detail
export function getFacilityBookingDetail(applicationId, data) {
    return get(
        `/rest/facility/api/v1/publ/application/${applicationId}/review`,
        data
    );
}

// 场地Facility查询可以添加的设备
export function facilityEquipmentsApi(params) {
    // playDate, venueId, fvrId, ssnStartTime, ssnEndTime
    return get(`/rest/facility/api/v1/publ/facility-info/equipments`, params)
}


//查询广告接口
export function getAd(code) {
    // return get(`/rest/cms/api/v1/publ/home/banner`);
    return get(`/rest/cms/api/v1/publ/content-post/BANNER?locnCode=${code}`);
}

//check in接口
export function getCheck(data) {
    return get(`publ/check-in`, data);
}

//登录接口login
export function login(data) {
    return post(`/rest/patron/api/v1/3s/login`, data);
}

//获取用户登录信息
export function getUserInfo() {
    return get(`/rest/patron/api/v1/publ/ind-patrons/info`);
}

//获取par-Q
export function getParInfo(data) {
    return get(`rest/param/api/v1/publ/parq`, data);
}

//确认par-Q
export function confirmParInfo() {
    return;
}

//获取facility declaration
export function getFacilityDeclaration(data) {
    return get(`/rest/facility/api/v1/publ/facility-info/declaration`, data);
}

//确认facility declaration
export function confirmFacilityDeclaration(data) {
    return post(
        `/rest/facility/api/v1/publ/application/${data.applicationId}/declarations`,
        data.declrList
    );
}

//program获取declaration
export function getProgrammeDeclaration(data) {
    return get(
        `/rest/programme/api/v1/publ/activities/${data.activityId}/declarations`,
        data
    );
}

//program确认declaration
export function confirmProgrammeDeclaration(data) {
    return post(
        `/rest/programme/api/v1/publ/application/${data.applicationId}/declarations`,
        data
    );
}

//查询支付方式
export function getPayway() {
    return get(`/rest/param/api/v1/publ/channel-pay-methods`);
}

// 支付成功 (申请信息&金额信息)
export function getPaymentSuccess(applld) {
    return get(
        `/rest/programme/api/v1/publ/application/${applld}/acknowledgement`
    );
}

export function getPaymentSuccessFacility(applld) {
    return get(`/rest/facility/api/v1/publ/application/${applld}`);
}

//select coach查询教练
export function getCoach(data) {
    return post(
        `/rest/patron/api/v1/publ/cor-service-provider/member/page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        // `rest/programme-catalog/api/v1/publ/activity-params/coaches`,
        {pidList: data.coachFilter}
    );
}

export function programmeSearch(keyword) {
    return get(
        `/rest/programme-catalog/api/v1/publ/activities/suggestions/detail/${keyword}`
    );
}

export function programmeSearchByNo(No) {
    return get(`/rest/programme-catalog/api/v1/publ/activities?activityIds=${No}`);
}

//Facility查询预订
export function queryBooking(data) {
    return get(`/rest/facility/api/v1/3s/facility-check-in/list`, data);
}

//查询报名的课程
export function queryClass(data) {
    return get(`/rest/programme/api/v1/publ/book`, data);
}

// filter过滤-1
export const getProgrammeFilter1 = () => {
    return get(`/rest/cms/api/v1/publ/contents/prg-search-filter`);
};

// filter过滤-2
export const getProgrammeFilter2 = () => {
    return get(
        `/rest/programme-catalog/api/v1/publ/activity-params/filter-enums`
    );
};

// 查询场馆房间
export const getCCHSportsVenue = (venueId, courtType, params) => {
    return get(
        `/rest/facility-catalog/api/v1/publ/facilities/venues/${venueId}/court-type/${courtType}`,
        params
    );
};

// 获取facility session
export function getFacilitySession(params) {
    return get(
      `/rest/facility-catalog/api/v1/publ/facilities/period`,
      params
    )
  }

// 提交场馆房间申请
export const getApplication = (data) => {
    return post(`/rest/facility/api/v1/publ/application`, data);
};

// 轮询申请结果
export const getApplicationResult = (params) => {
    return get(`/rest/facility/api/v1/publ/application/-/queue`, params);
};

// 查询朋友列表 - 旧
export const getFriendList = (params) => {
    return get(`/rest/patron/api/v1/publ/ind-patrons/friends`, params);
};
// 查询好友 - 新
export const queryFriends = (params) => {
    return get(`/rest/patron/api/v1/publ/ind-patrons/fuzzy`, params);
};

// 查询课程详情包含优惠券信息
export const getProgrammeDetailCoupon = (params) => {
    return get(
        `/rest/programme/api/v1/publ/activities/${params.activityId}/info`,
        params
    );
};

// 获取type D分组列表
export function getActivityGroup(activityId) {
    return get(
        `/rest/programme/api/v1/publ/activities/${activityId}/activity-group`,
    );
}

// 获取type B/C/D班级列表
export const getActivityGroupClass = (params) => {
    return get(
        `/rest/programme/api/v1/publ/activity-class`,
        params
    );
};
// 查询课程信息
export const getProgrammeDetail = (params) => {
    return get(
        `/rest/programme-catalog/api/v1/publ/activities/${params.activityId}`,
        params
    );
};

// 课程预订
export const applyBookingProgramme = (params) => {
    return post(`/rest/programme/api/v1/publ/application`, params);
};

//提交课程预订
export const submitApplyBookingProgramme = (params) => {
    return post(
        `/rest/programme/api/v1/publ/application/${params.applicationId}/submission`,
        params
    );
};

// 查询预订课程信息
export const getBookingProgrammeDetail = (params) => {
    return get(`/rest/programme/api/v1/publ/application/${params.applicationId}`);
};

// 课程页面 获取当前运动可预订的时间以及教练限制
export const getDateCoachByactivityType = ({activityType}) => {
    return get(
        `/rest/programme-catalog/api/v1/publ/activities/search-filters?activityType=${activityType}`
    );
};

// 课程页查询运动类型
export const getSportsInProgramme = () => {
    // return get("/rest/param/api/v1/publ/enums/activity_type/grouping-by/sports");
    return get(
        "/rest/param/api/v1/publ/enums/activity_type/grouping-by/sec/sports_type"
    );
};

// 课程页查询课程
export const getProgramme = (params, isShowGlobalLoading) => {
    return get(
        "/rest/programme-catalog/api/v1/publ/activities",
        params,
        isShowGlobalLoading
    );
};

// 推荐课程查询
export const getRecommendProgramme = () => {
    return get("rest/cms/api/v1/publ/content-post/BANNER?locnCode=PROGRAMME");
};

// 查询programme地址
export const getProAddr = () => {
    return get("/rest/programme-catalog/api/v1/publ/activity-params/venues");
};

// 获取programme 的par-Q
export function getProgrammeParQ(data) {
    return get(`/rest/param/api/v1/publ/parq`, data);
}

// 提交programme 的par-Q
export function confirmProgrammeParQ(data) {
    return post(
        `/rest/programme/api/v1/publ/application/${data.applicationId}/parq`,
        data
    );
}

// 当前用户退出登陆
export function logout(data) {
    return post(`/rest/patron/api/v1/publ/logout`, data);
}

// 添加programme partner
export function addProgrammeParner(data) {
    return post(
        `/rest/programme/api/v1/publ/application/${data.applicationId}/partner?partonIdOrAlias=${data.partonIdOrAlias}`,
        data
    );
}

// 删除programme partner
export function deleteProgrammeParner(data) {
    return deleteA(
        `/rest/programme/api/v1/publ/application/${data.applicationId}/partner/${data.invitationId}`,
        data
    );
}

// 添加、删除 facility partner
export function editFacilityParner(data) {
    return post(
        `/rest/facility/api/v1/publ/application/partner/invitations`,
        data
    );
}

// 通过hkCard、qrcode checkin for programme
export function checkinforProgramme(data) {
    // return post(`/rest/programme/api/v1/publ/check-in/direct`, data);
    return post(`/rest/programme/api/v1/maint/check-in/direct`, data);
}
// 课程qrcode签到
export function checkinProgrammeQrcode(data) {
    return post(`/rest/programme/api/v1/publ/check-in/qrcode`, data);
}
// 课程hkid签到
export function checkinProgrammeHkid (data) {
    return post(`/rest/programme/api/v1/publ/check-in/hkid`, data);
}





// 通过hkCard、qrcode checkin for facility
export function checkinForFacility(data) {
    return post(`/rest/facility/api/v1/publ/facility-check-in`, data);
}

export function checkHkidExist(data) {
    return post("/rest/patron/api/v1/3s/hkno-exist", data);
}

// 获取注册协议
export function getRegAgreement() {
    return get(
        // `/rest/cms/api/v1/publ/contents/important-notes/ind-reg`
        `/rest/cms/api/v1/publ/contents/register-protocol/ind-reg`
    );
}

// 邮箱注册 验证邮箱是否被占用
export function uniqueEmail(email) {
    return post(`/rest/patron/api/v1/3s/non-realname/check-email?email=${email}`);
}

// 获取邮箱注册验证码
export function getVerifyCode(email) {
    return post(
        `/rest/mail/api/v1/publ/mails/ltd-verifycode?emailAddress=${email}`
    );
}

// 验证码提交对比
export function verify(data) {
    return get("/rest/mail/api/v1/publ/mails/verify", data);
}

// 用户注册
export function registerMember(params) {
    // 邮箱注册 示例
    // {
    //     "regMethod":"EMAIL",
    //     "email":"xxx"
    // }

    // HKID注册示例
    // {
    //     "regMethod": "HKNO",
    //     "engSurname": "ZHOU",
    //     "engGivenName": "wei",
    //     "docType": "HK_IDCARD",
    //     "docNo": "R1146636",
    //     "gender": "M",
    //     "dob": "2022-01-01"
    // }
    return post("/rest/patron/api/v1/3s/register-patron", params);
}

// 用户注册
export function register(params) {
    return post("/rest/patron/api/v1/3s/register-patron", params);
}

// 实名认证
export function realNameAuth(params) {
    return put("/rest/patron/api/v1/3s/supplement-realname", params);
}

// 取消facility预订
export function cancelBookingForFacility(data) {
    return deleteA(
        `/rest/facility/api/v1/publ/application/${data.applicationId}?reallocatePair=false`,
        data
    );
}

// 取消programme预订
export function cancelBookingForProgramme(data) {
    return deleteA(
        `rest/programme/api/v1/publ/application/${data.applicationId}/cancel`,
        data
    );
}

// pay for facility
export function payForFacility(data) {
    return post(
        `/rest/facility/api/v1/publ/application/payment/complete?applicationId=${data.applicationId}&payMethod=CASH`,
        data
    );
}

// pay for programme
export function payForProgramme(data) {
    return post(`/rest/programme/api/v1/publ/application/payment`, data);
}

// 获取programme支付请求体数据
export function getProgrammePayRequst(appId) {
    return get(`/rest/programme/api/v1/publ/application/${appId}/payment/amount`);
}

//查询预订session信息
export function getBookingSessionInfo(applicationId, params) {
    return get(
        `/rest/facility/api/v1/publ/application/${applicationId}/review`,
        params
    );
}

//发起预订
export function bookingSession(data) {
    return post(`/rest/facility/api/v1/publ/application`, data);
}

//查排队结果
export function getQueueResult(waitingId) {
    return get(
        `/rest/facility/api/v1/publ/application/-/queue?waitingId=${waitingId}`,
        null,
        false
    );
}

//查可预订场馆

export function getVenueCourt(venueId, courtType, params) {
    return get(
        `/rest/facility-catalog/api/v1/publ/facilities/venues/${venueId}/court-type/${courtType}`,
        params
    );
}

//取消预订
export function cancelBooking(applicationId) {
    return deleteA(
        `/rest/facility/api/v1/publ/application/${applicationId}?reallocatePair=false`
    );
}

//发起支付获取支付链接
export function getPayLink(data) {
    return post(`/rest/facility/api/v1/publ/application/pay-link`, data);
}

//查询预订监护人
export function getBookingGuardian() {
    return get(`/rest/patron/api/v1/publ/ind-patrons/book-guardian`);
}

//支付结果查询
export function payQuery(fbaId) {
    return get(`/rest/facility/api/v1/publ/application/pay-query/${fbaId}`);
}

// 添加多个监护人
export function addMultiGuardians(data) {
    return post(`/rest/patron/api/v1/publ/ind-patrons/book-guardians`, data);
}

//获取图片
export function getImgStream(fileId, isShowLoading) {
    if (!fileId) {
        return;
    }
    return get(`/rest/file/api/v1/publ/files/${fileId}/info`, isShowLoading);
}

export function getImgStreams(param, isShowLoading) {
    return get(`/rest/file/api/v1/publ/files/-/info`, param, isShowLoading)
}

//关系枚举
export function getRelationEnum() {
    return get(`/rest/param/api/v1/publ/enums/patron_book_guardian_type`);
}

// 紧急联系人接口
export function getAllBookEmergencyApi() {
    return get(`/rest/patron/api/v1/publ/ind-patrons/book-emergency/all`)
}


// 删除-紧急联系人接口
export function delBookEmergencyApi(id) {
    if (!id) return
    return deleteA(`/rest/patron/api/v1/publ/ind-patrons/book-emergency?ids=${id}`)
}

// 更新紧急联系人
export function updateEmergencyContactsList(data) {
    return post(`/rest/patron/api/v1/publ/ind-patrons/book-emergency/batch`,
        data
    )
}

//查询预订声明
export function getBookingDeclaration(fatId, playDate) {
    return get(
        `/rest/facility/api/v1/publ/facility-info/declaration?fatId=${fatId}&playDate=${playDate}`
    );
}

//勾选预订声明
export function checkBookingDeclaration(applicationId, data) {
    return post(
        `/rest/facility/api/v1/publ/application/${applicationId}/declarations`,
        data
    );
}

// 校验课程是否可预订
export function checkProgrammeBooking(activityId, itemId) {
    return get(
        `/rest/programme/api/v1/publ/application/check?activityId=${activityId}&itemId=${itemId}`
    );
}

// 查询课程预订声明
export function getProgrammeBookingDeclaration(activityId) {
    return get(
        `/rest/programme/api/v1/publ/activities/${activityId}/declarations`
    );
}

// 提交课程预订声明
export function submitProgrammeBookingDeclaration(data, applicationId) {
    return post(
        `/rest/programme/api/v1/publ/application/${applicationId}/declarations`,
        data
    );
}

// 查询参加人列表
export function getParticipantList(params) {
    return get(
        `/rest/programme/api/v1/maint/application/partner/kiosk/list?pid=${params.pid}`
    );
}

// 课程预订参加人保存
export function saveParticipant(data) {
    return post(`/rest/programme/api/v1/maint/application/partner/kiosk`, data);
}

//获取支付小票
export function getPayReceipt(fbaId) {
    return get(
        `/rest/facility/api/v1/publ/receipts/fac-pay-receipt?fbaId=${fbaId}`
    );
}

// 课程预订紧急联系人保存
export function saveEmergencyContact(data) {
    return post(`/rest/patron/api/v1/publ/ind-patrons/book-emergency`, data);
}

// 课程预订查询紧急联系人
export function getEmergencyContact() {
    return get(`/rest/patron/api/v1/publ/ind-patrons/book-emergency`);
}

// 课程预订根据邮箱地址查询参加人
export function getParticipantByEmail(param) {
    return get(
        `/rest/programme/api/v1/maint/application/partner/kiosk?pid=${param.pid}&email=${param.email}`
    );
}

// 课程预订parQ结果查询
export function getParQResult(param) {
    return get(
        `/rest/programme/api/v1/publ/application/${param.applicationId}/parq/answer?pid=${param.pid}`
    );
}

// 课程发起支付获取支付链接 ---- 废弃
export function getProgrammePayLink(data) {
    return post(`/rest/programme/api/v1/3s/application/payment/link`, data);
}

// 课程发起支付获取支付链接
export function getCspPayLink(data) {
    return post(`/rest/programme/api/v1/publ/application/payment/pay-link`, data);
}

// 课程预订查询支付金额
export function getProgrammePayAmount(param) {
    return get(
        `/rest/programme/api/v1/maint/application/${param.applicationId}/payment/amount?pid=${param.pid}`
    );
}

// 课程预订支付结果查询 fixme -----废弃,by bingjun,24.05.24 10:34
export function getProgrammePayQuery(fbaId) {
    return get(`/rest/programme/api/v1/3s/application/payment/query/${fbaId}`);
}

//Kiosk - Facility抽签查询session时段
export function getBallotSession(data) {
    return post(
        `/rest/facility-catalog/api/v1/publ/facilities/ballot-session`,
        data
    );
}

//Kiosk - Facility抽签选择session校验
export function checkBallotSession(data, pid) {
    return post(`/rest/facility/api/v1/3s/ballot/check-choice?pid=${pid}`, data);
}

//Kiosk - Facility抽签选择session保存
export function saveBallotSession(pid, data) {
    return post(`/rest/facility/api/v1/3s/ballot/choices?pid=${pid}`, data);
}

//Kiosk - Facility抽签查询选择的抽签信息
export function getBallotSessionMsg(applicationId, pid) {
    return get(
        `/rest/facility/api/v1/3s/ballot/${applicationId}/choices?pid=${pid}`
    );
}

// 获取抽签后续操作流程说明
export function getballotingGuide() {
    return get(
        `/rest/param/api/v1/publ/enums/balloting_guide`
    )
}

//获取协议信息
export function getBallotInfo() {
    return get(`/rest/param/api/v1/publ/enums/kiosk_ballot_info`);
}

//获取参加人类别
export function getPartnerType() {
    return get(`/rest/param/api/v1/publ/enums/partner_type`);
}

// 抽签确认
export function confirmBallotSession(applicationId, pid) {
    return post(
        `/rest/facility/api/v1/3s/ballot/${applicationId}/submission?pid=${pid}`
    );
}

//Kiosk - Facility抽签历史按月查询
export function getBallotHistoryDate(fatId, dateCondition) {
    return get(
        `/rest/facility/api/v1/3s/ballot/history-date?fatId=${fatId}&dateCondition=${dateCondition}`
    );
}

//查询抽签协议
export function getBallotProtocol() {
    return get(`/rest/cms/api/v1/publ/contents/ballot-protocol/fac_ballot`);
}

//Kiosk - Facility抽签历史按月查询
export function getBallotHistoryResult(fatId, ballotDate) {
    return get(
        `/rest/facility/api/v1/3s/ballot/history-result?fatId=${fatId}&ballotDate=${ballotDate}`
    );
}

// 课程抽签申请
export function applyProgrammeBallot(data) {
    return post(`rest/programme/api/v1/publ/balloting/application`, data);
}

// 签署课程抽签parQ
export function applyProgrammeBallotParQ(data) {
    return post(
        `/rest/programme/api/v1/publ/balloting/application/${data.applicationId}/parq`,
        data
    );
}

// 提交课程抽签报名申请
export function submitProgrammeBallot(data) {
    return post(
        `rest/programme/api/v1/publ/balloting/application/${data.applicationId}/submit`,
        data
    );
}

// 查询抽签申请信息
export function getProgrammeBallotInfo(data) {
    return get(
        `/rest/programme/api/v1/publ/application/${data.applicationId}/acknowledgement`,
        data
    );
}

// 课程抽签历史
export function getProgrammeBallotHistory(param) {
    return get(
        `/rest/programme/api/v1/publ/balloting/application/activity-anno`,
        param
    );
}

// 课程抽签历史中奖名单
export function getProgrammeBallotHistoryResult(activityId) {
    return get(
        `/rest/programme/api/v1/publ/balloting/application/${activityId}/anno`
    );
}

// 获取抽签公布日期
export function getProgrammeBallotAnnDate(activityId) {
    return get(`/rest/programme/api/v1/publ/activities/${activityId}/enrol-list`);
}

// 获取课程抽签申明
export function getProgrammeBallotDeclar(applicationId) {
    return get(
        `/rest/programme/api/v1//publ/balloting/application/${applicationId}/declarations`
    );
}

// 提交课程抽签申明
export function submitProgrammeBallotDeclar(data, applicationId) {
    return post(
        `/rest/programme/api/v1/publ/balloting/application/${applicationId}/declarations`,
        data
    );
}

// 免费课程先到先得预订
export function confirmProgrammeFreeSubmit(applicationId, data) {
    return post(
        `/rest/programme/api/v1/publ/application/${applicationId}/confirm`,
        data
    );
}

//取消抽签
export function cancelBallot(applicationId) {
    return deleteA(`/rest/facility/api/v1/3s/ballot/cancel/${applicationId}`);
}

// 查询当前用户是否被惩罚（预订场地不签到一定次数会被惩罚，无法再次预订场地）
export function checkPenalty() {
    return get(`/rest/facility/api/v1/publ/application/check-penalty`);
}

// kiosk自助机灯带控制
export function controlLight(params) {
    return requestLocalhost(`/api/DeviceControl/LightsControl`, params);
}

// iam smart登录时获取三方提示页面url的接口
export function getQrPage(params) {
    return get(`/rest/iams/api/v1/publ/iamSmart/getQRPage`, params);
}

// iam smart登录获取用户信息
export function getUserInfoByIamSmart(params) {
    return get(`/rest/iams/api/v1/publ/iamSmart/getToken`, params);
}

// iam smart 注册时跳转三方提示页面
export function getRegisterPage(params) {
    return post(
        `/rest/iams/api/v1/publ/iamSmart/anonymous/form-filling?action=${params.action}&businessId=${params.businessId}`
    );
}

export function getIamRegisterInfo(params) {
    return get(
        `/rest/iams/api/v1/publ/iamSmart/anonymous/form-filling/result`,
        params
    );
}

// 查询用户有优惠券列表
export function getPatCoupon(params) {
    return get(`/rest/patron/api/v1/publ/pat-coupon`, params);
}

// 查询用户积分
export function getPatPoints(params) {
    return get(`/rest/patron/api/v1/publ/pat-points`, params);
}

export const local = `http://localhost:8080`;
// export const local = 'http://172.18.228.115:8080'
// t300 支付
export function posPay(data) {
    console.log("pos机支付接口地址:", `${local}/t300/toPay`);
    return post(`${local}/t300/toPay`, data, true, {
        headerObj: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        timeout: 180 * 1000,
    });
}

export function toPayByGP(data) {
    console.log("亮码付支付接口地址:", `${local}/t300/toPayByGP`);
    return post(`${local}/t300/toPayByGP`, data, true, {
        headerObj: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        timeout: 180 * 1000,
    });
}

// /publ/com-params/code/POINTS_RATIO
export function getPointsRatio(params) {
    return get(`/rest/param/api/v1/publ/com-params/code/POINTS_RATIO`, params);
}

// /publ/com-params/code/POINTS_HKD_RATIO
export function getPointsToMoneyRatio(params) {
    return get(
        `/rest/param/api/v1/publ/com-params/code/POINTS_HKD_RATIO`,
        params
    );
}

// 预订后课程支付前添加设备
export function addProgrammeApplicationEquipment(data) {
    return post(`/rest/programme/api/v1/publ/application/add-on-equipment`, data);
}

// 查询当前语言
export function getProviderLanguage() {
    return get(`/rest/param/api/v1/publ/enums/provider_language`);
}

// T&C 支付页 条款与条件
export function getIndividualTermsApi() {
    return get(`/rest/param/api/v1/publ/declarations/individual-terms`);
}


export function getBookingDates(params) {
    return get(`/rest/facility-catalog/api/v1/publ/facilities/booking-dates`, params);
}

// C端查询parQ声明
export function getParQTermsApi() {
    return get(`/rest/param/api/v1/publ/declarations?modName=PARQ&code=parq-terms`);
}

// 参加人list 不分页
export function getPlayerList() {
    return get(`/rest/patron/api/v1/publ/patron-partner`);
}

// 邀請狀態枚舉值
export function getpartnerstatus() {
    return get(`/rest/param/api/v1/publ/enums/patron_partner_ivt_status`);
}

// csp發送邀請郵件
export function postPartnerIvt(applicationId, data) {
    return post(
        `/rest/programme/api/v1/publ/application/${applicationId}/partner`, data
    );
}

// cch發送邀請郵件
export function postCCHPartnerIvt(applicationId, data) {
    return post(
        `/rest/facility/api/v1/publ/application/${applicationId}/partner`, data
    );
}


// 参加人list 不分页
export function getPlayerPopupPage(params) {
    return get(`/rest/patron/api/v1/publ/patron-partner`, params)
}

// 获取CSP设备信息
// /programme/api/v1/publ/activity-equip/equipList

export function getCSPEquipListByActivityId(activityId) {
    return get(`/rest/programme/api/v1/publ/activity-equip/equipList`, {activityId})
}


// 个人中心-场地-课程-用户主动取消预定接口
export function cspCancelBookingApi(applicationId, data) {
    console.log('cspCancelBookingApi')
    if (!applicationId) return false
    return deleteA(`/rest/programme/api/v1/publ/application/${applicationId}/cancel`, data)
}


// FCFS 课程 进行check out，替代原来的 /rest/programme/api/v1/publ/application/web
export function checkoutByFcfsCspDetails(data) {
    return post(`/rest/programme/api/v1/publ/application/web`, data)
}

// 根据订单id，查看订单详情
export function getCspOrderInfo(appId) {
    return get(`/rest/programme/api/v1/publ/application/${appId}`)
}

// MyProfile-Detail查询session /programme/api/v1/publ/balloting/application/sessions
export function getCspSession(params) {
    return get(`/rest/programme/api/v1/publ/balloting/application/sessions`, params)
}


// 获取该课程的可添加设备列表
// /publ/activity-equip/equipList
export function getEquipListByActiveId(params) {
    if (params.activityId) {
        return get(`/rest/programme/api/v1/publ/activity-equip/equipList`, params)
    }
}


// 保存 csp 参加人
// /rest/programme/api/v1/publ/application/${applId}/attendee   post
export function saveAttendeeList(applId, data) {
    if (applId) {
        return post(`/rest/programme/api/v1/publ/application/${applId}/attendee`, data)
    }
}


// 获取 csp 参加人
// /rest/programme/api/v1/publ/application/${applId}/attendee   get
export function getAttendeeList(applId) {
    if (applId) {
        return get(`/rest/programme/api/v1/publ/application/${applId}/attendee`)
    }
}


// 查询facility监护人信息
export function getGuardianInfo() {
    return get(`/rest/patron/api/v1/publ/ind-patrons/book-guardian`)
}

// 添加、删除、修改监护人信息
export function modifyGuardianInfo(data) {
    return post(`/rest/patron/api/v1/publ/ind-patrons/book-guardians`,
        data
    )
}

// 删除监护人信息
export function deleteGuardianInfo(id) {
    if (!id) return
    return deleteA(`/rest/patron/api/v1/publ/ind-patrons/book-guardians?ids=${id}`)
}

// 检查是否处于黑名单中
export function checkBlacklist() {
    return get(
        `/rest/patron/api/v1/publ/ind-blacklist/check`
    )
}

// 获取 csp 支付状态及金额等
export function acknowledgemenApi(params) {
    return get(`/rest/programme/api/v1/publ/application/acknowledgement`,
        params
    )
}

// 获取 csp 支付状态及金额等
export function getCspPayStatusByApplId(params) {
    return get(`/rest/programme/api/v1/publ/receipts/app/programme-pay-receipt-info`,
        params
    )
}

// 获取 csp 支付状态及金额等
export function getCspApplDetail(applicationId, params) {
    return get(`/rest/programme/api/v1/publ/application/list?${applicationId}`,
        params
    )
}

// 获取 cch 支付状态及金额等
export function getCchPayStatus(waitingId) {
    return get(`/rest/facility/api/v1/publ/application/pay-result/${waitingId}`)
}

// 课程抽签提交申请
export function submitBallotingApplication(data) {
    return post(
        `/rest/programme/api/v1/publ/balloting/application/web/submit`,
        data
    )
}

// 课程抽签提交接口
export function programmeBallotingSubmitApi(applId) {
    return get(
        `/rest/programme/api/v1/publ/balloting/application/${applId}/commit`,
    )
}

// 课程抽签提交详情
export function getProBallotingAppDetail(applicationId) {
    return get(
        `/rest/programme/api/v1/publ/balloting/application/${applicationId}`,
    )
}

// CCH取消预订
export function cancelCCHOrder(data) {
    return put(
        `/rest/facility/api/v1/publ/application/cancel/${data.fbaId}`, data
    )
}

// CSP取消预定
export function programmeCancelBokingApi(applicationId, data) {
    if (!applicationId) return
    return deleteA(
      `/rest/programme/api/v1/publ/application/${applicationId}/cancel`,
      data
    )
  }

export function getProgrammeNeedRealName() {
    return get(
        `/rest/param/api/v1/publ/com-params/code/PGM_ENROLL_REAL_NAME_AUTH`
    )
}
// 获取CCH大类
export function getFacilityCategory() {
  return get(`/rest/param/api/v1/publ/enums/fac_activity`)
}
export function getSsoLoginEnable() {
  return get(`/rest/param/api/v1/publ/com-params/code/ENABLE_SSO_LOGIN`)
}
export function getSsoLoginUrl() {
  return get(`/rest/param/api/v1/publ/com-params/code/CIAM_SSO_LOGIN_URL`)
}
// 查找未成年人儿童调用接口：
export function childOverageApi() {
  return get(`/rest/param/api/v1/publ/com-params/code/CHILD_OVERAGE`)
}
export function loginSsoApi() {
  return get(`/rest/patron/api/v1/publ/sso/token`)
}
// cch 先到先得预订，可查看几天的预订数据
export function getCCHFCFSDates() {
  return get(`/rest/param/api/v1/publ/com-params/code/fac_booking_dates`)
}