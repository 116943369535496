/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: licuixia
 * @Date: 2023-02-08 22:57:20
 * @LastEditors: licuixia
 * @LastEditTime: 2023-09-25 17:28:14
 */
import { useRouter } from 'vue-router'
import { computed } from 'vue'
// import { inject } from '@vue/runtime-core';
export default function (isSpecialMode) {
    // let hideFooter = false;
    // onBeforeMount(() => {
    //     // 需要隐藏footer组件的页面
    //     let pageList = ['home'];
    //     const router = useRouter();
    //     hideFooter = computed(() => {
    //         console.log(router.currentRoute.value.name)
    //         return pageList.includes(router.currentRoute.value.name);
    //     });
    // });

    // 需要隐藏footer组件的页面
    let pageHideFooter = ['paymentFailed', "selectFriend", "selectCoupon", "more", "selectFriend", "virtualQueue", "mobelPaySuccess"];
    const router = useRouter();
    let hideFooter = computed(() => {
        let route = router.currentRoute.value.name;
        return route !== undefined && pageHideFooter.includes(route);
    });

    // 需要隐藏header组件的页面
    let pageHideHeader = [
        'home',
        'menu',
        'login',
        'octopus',
        'alipay',
        'creidtCard',
        'payway',
        'checkIn',
        'print',
        'printSuccess',
        'printFailed',
        'orderDetail',
        'paymentSuccess',
        'paySuccess',
        'paymentFailed',
        "selectFriend",
        "selectCoupon",
        "more",
        "facilityDetail",
        "selectCourt",
        "selectFriend",
        "register",
        "registerSuccess",
        'selectDateTime',
        'ballotSuccess',
        'mobelPaySuccess',
        // 'ballotSelect'
    ];
    // 特殊模式（页面只展示一半时）也不需要展示header组件
    // let isSpecialMode = inject('isSpecialMode');
    let hideHeader = computed(() => {
        let route = router.currentRoute.value.name;
        // console.log(route , '------------------------------', !pageHideHeader.includes(route), isSpecialMode.value);
        // console.log('--------------111----------------', route !== undefined && !pageHideHeader.includes(route) && !isSpecialMode.value);
        // return route !== undefined && !pageHideHeader.includes(route) && !isSpecialMode.value;
        return route !== undefined && pageHideHeader.includes(route);
    });

    return {
        hideFooter,
        hideHeader,
    };
}