<template>
  <div :class="['page-head', { 'is-light': isLight }]">
    <div class="btn-wrap">
      <!--    back-->
      <div class="btn go-back" @click="goBack" v-show="!isHideGoBack">
        <img class="img" src="@/assets/image/icon_back.png"/>
        <!-- <span class="txt">{{ $t('Back') }}</span> -->
      </div>
      <!--    text-->
      <div class="center-content" :class="[{'ml':isHideGoBack&&!isHideHome},{'mr':!isHideGoBack&&isHideHome}]">
        <div class="space">
          <slot>{{ title || returnText }}</slot>
        </div>
        <div class="btn search" v-show="isNeedSearch" @click="handleSearch"></div>
      </div>
      <!--      back-->
      <div class="btn go-home" @click="goHome" v-show="!isHideHome">
        <img class="img" src="@/assets/image/icon_home.png"/>
        <!-- <span class="txt">{{ $t('home') }}</span> -->
      </div>
    </div>
    <slot name="content" />
  </div>
</template>

<script>
import {useRouter} from 'vue-router'
import {computed, reactive, toRefs, watch, getCurrentInstance} from 'vue';
import {useI18n} from 'vue-i18n';
import i18n from "@/i18n";

export default {
  props: {
    routeName: {
      type: String,
      default: ''
    },
    headStyle: {
      type: String,
      default: 'dark',
    },
    title: {
      type: String,
      default: ''
    },
    hideHome: { // 隐藏home按钮
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter();
    const {t} = useI18n();
    const Bus = getCurrentInstance().appContext.config.globalProperties.$bus;
    const data = reactive({
      // returnText: '',
      isNeedSearch: false,
      isNeedLogout: false,
      isHideHome: false,
      isHideGoBack: false
    });
    const goBack = () => {
      if (router.currentRoute.value.name === 'ballotSuccess') {
        router.push({name: 'menu'});
        return
      }
      let routeName = '';
      if (router.currentRoute.value.meta && router.currentRoute.value.meta.returnPage) {
        routeName = router.currentRoute.value.meta.returnPage;
        // console.log('routeName', routeName);
      }
      if (props.routeName) {
        routeName = props.routeName;
      }
      if (router.currentRoute.value.meta && router.currentRoute.value.meta.beforeBackCall) {
        Bus.emit('handleBeforeNavBack');
      } else {
        if (routeName) {
          router.push({name: routeName, query: router.currentRoute.value.query});
        } else {
          router.back();
        }
      }

    }
    const goHome = () => {
      if (router.currentRoute.value.meta && router.currentRoute.value.meta.beforeGoHomeCall) {
        Bus.emit('handleBeforeGoHome');
      } else {
        router.push({name: 'menu'});
      }

    }
    const returnText = computed(() => {
      let returnText = '';
      if (router.currentRoute.value.name) {
        if (router.currentRoute.value.meta && router.currentRoute.value.meta.returnText) {
          returnText = t(router.currentRoute.value.meta.returnText);
        }

        if (router.currentRoute.value.name == 'ballotSelect' || router.currentRoute.value.name == 'selectDateTime') {
          let name = JSON.parse(sessionStorage.getItem('selectedSport'))
          let lang = i18n.global.locale.value
          let tmpRouteName = router.currentRoute.value.name
          let isBallot = (tmpRouteName == 'selectDateTime') ? false : true
          if (lang === 'zh-cn') {
            returnText = name && name.scName + (isBallot ? ' 抽签' : '')
          } else if (lang === 'zh-hk') {
            returnText = name && name.tcName + (isBallot ? ' 抽籤' : '')
          } else {
            returnText = name && name.enName + (isBallot ? ' Balloting' : '')
          }
        }
      }
      return returnText;
    });

    let isLight = props.headStyle === 'light';

    const handleSearch = () => {
      Bus.emit('handleNavSearch');
    }

    const handleLogout = () => {
      Bus.emit('handleNavLogout', () => {
        console.log('退出！');
      });
    }

    watch(() => props.hideHome, (v) => {
      data.isHideHome = v || false
    })

    // 监听路由变化，修改页面名称
    watch(
        () => router.currentRoute.value.name,
        () => {
          if (router.currentRoute.value.meta) {
            // data.returnText = router.currentRoute.value.meta.returnText;
            data.isNeedSearch = router.currentRoute.value.meta.isNeedSearch;
            data.isNeedLogout = router.currentRoute.value.meta.isNeedLogout;
            data.isHideHome = router.currentRoute.value.meta.isHideHome;
            data.isHideGoBack = router.currentRoute.value.meta.isHideGoBack;
          }
        },
        {immediate: true}
    );

    return {
      goBack,
      goHome,
      isLight,
      returnText,
      handleSearch,
      handleLogout,
      ...toRefs(data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/init.scss';

.page-head {
  .btn-wrap {
    padding: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ft(112);
  }

  .go-home, .go-back {
    text-align: left;
    width: 170px;
    color: var(--White, #FFF);
    font-size: ft(16);
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    height: 60px;
    line-height: 60px;
    @extend .def-gap;

    .txt {
      text-transform: capitalize;
    }

    .img {
      @extend .icon-24;
    }
  }

  .go-home{
    text-align: right;
  }

  .center-content{
    display: flex;
    align-items: center;
    flex: 1;

    .space {
      flex-grow: 1;
      //@include text-color('color-text-dark');
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      font-family: Barlow;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      color: #fff;
      flex: 1;
    }

    &.ml{
      margin-left: ft(115);
    }
    &.mr{
      margin-right: ft(115);
    }
  }

  .search,
  .logout,
  .go-home {
    flex-shrink: 0;
  }

  .search,
  .logout {
    width: 0.86rem;
    height: 0.8rem;
    margin-right: 0.4rem;
  }

  .search,
  .logout {
    @include color-bg-primary('color-nav-icon-bg');
    background-image: url('@/assets/image/ic_search_white.png');
    background-repeat: no-repeat;
    background-size: 0.38rem 0.38rem;
    background-position: center center;
    border-radius: 0.16rem;
    @include box-shadow('color-btn-programme');
  }

  .logout {
    @include color-bg-primary('color-nav-icon-bg');
    background-image: url('@/assets/image/icon_logout.png');
    background-size: 0.4rem 0.38rem;
    background-position: 0.28rem center;
  }

  &.is-light {
    .go-back {
      @include text-color('color3');
      border: 0.02rem solid #FFFFFF;

      &::before {
        content: '';
        background: url('@/assets/image/icon_home.png') no-repeat center / 100% 100%;
        // background: url('@/assets/image/icon_back_white.png') no-repeat center / 100% 100%;
      }
    }

    .space {
      z-index: 10;
      color: #FFFFFF;
    }
  }
}
</style>
