/*
 * @Descripttion:
 * @Version: 1.0
 * @Author: licuixia
 * @Date: 2023-02-08 22:57:20
 * @LastEditors: licuixia
 * @LastEditTime: 2023-10-30 15:18:24
 */
import {logout} from '@/http/api'

export function logoutPage(_that) {
    logout().then(res => {
        if (res.code == 0) {
            localStorage.removeItem("Token")
            _that.$router.push({path: '/home'})
        }
    }).catch(err => {
        console.log(err)
    })
}

export function getWeekFromDate(date) {
    return new Date(date).getDay();
}

// 用户登录密码可用字符校验
export function pwdAllowStr(pwd) {
    let specialStr = ['\\(', '\\)', '`', '~', '\\!', '@', '#', '\\$', '%', '\\^', '&', '\\*', '\\–', '\\_', '\\+', '=', '\\|', '\\{', '\\}', '\\[', '\\]', ':', ';', '‘', '<', '>', ',', '\\.', '\\?'];
    let res = pwd.replace(/\d/g, '');
    res = res.replace(/[A-Za-z]/g, '');
    let exp = new RegExp(specialStr.join('|'), 'g');
    res = res.replace(exp, '');

    // 存在其他不允许的字符
    if (res.length > 0) {
        return false;
    }
    return true;
}

// 特殊字符
export let specialStr = ['(', ')', '`', '~', '!', '@', '#', '$', '%', '^', '&', '*', '–', '_', '+', '=', '|', '{', '}', '[', ']', ':', ';', '‘', '<', '>', ',', '.', '?'];

// 用户登录密码复杂度校验
export function pwdVerify(password) {
    let res = false;
    // 复杂度  密码必须至少包含有大写字母  小写字母 数字 特殊字符 中的三种
    let complexity = [0, 0, 0, 0];
    for (let char of password) {
        if (/[A-Z]/.test(char)) {
            if (complexity[0] === 0) {
                complexity[0] = 1;
            }
        }
        if (/[a-z]/.test(char)) {
            if (complexity[1] === 0) {
                complexity[1] = 1;
            }
        }
        if (/\d/.test(char)) {
            if (complexity[2] === 0) {
                complexity[2] = 1;
            }
        }
        if (specialStr.indexOf(char) > -1) {
            if (complexity[3] === 0) {
                complexity[3] = 1;
            }
        }
        // 复杂度  密码必须至少包含有大写字母  小写字母 数字 特殊字符 中的三种
        if (complexity[0] + complexity[1] + complexity[2] + complexity[3] >= 3) {
            res = true;
            break;
        }
    }

    return res;
}

export function phoneVerify(phone) {
    // 香港电话号码是8位数字
    return /^\d{8}$/.test(phone);
}

export function emailVerify(email) {
    return /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(email);
}

// 如果显示的是英文姓名，则First Name只显示首字母，其他信息用*代替，Last Name显示全部。
// 如果显示的邮箱地址，则全部显示。
// 如果显示的是手机号码，则只保留头2位和尾2位的手机号
export function formatName(lang, obj = {}, needRealName) {
    const {firstName = '', lastName = ''} = obj
    let name = ''
    if (firstName) {
        let _firstName = ''
        if (needRealName) {
            _firstName = firstName
        } else {
            let length = firstName.length
            if (length < 11) {
                _firstName = firstName.substring(0, 1) + new Array(length - 1).fill('*').join('')
            } else {
                _firstName = firstName.substring(0, 1) + new Array(10).fill('*').join('')
            }
        }
        name = lastName ? _firstName + ' ' + lastName : _firstName
    } else {
        if (needRealName) {
            if (!name && obj && obj.email && obj.email.trim()) {
                name = obj.email
            }
        } else {
            if (!name && obj && obj.email && obj.email.trim()) {
                name = obj.email
            }
            if (!name && obj && obj.phoneNo && obj.phoneNo.trim()) {
                let num = obj.phoneNo.toString()
                name = num.substr(0, 2) + '****' + num.substr(-2)
            }
        }
    }
    return name
}
export function formatName2(lang, obj, needRealName) {
    let name = ''
    // 英文情况下
    // if (lang.indexOf('en') > -1) {
    if (obj && obj.engGivenName && obj.engSurname) {
        // let firstName = obj.engGivenName.substring(0, 1) + '***'
        let firstName = ''
        if (needRealName) {
            firstName = obj.engGivenName
        } else {
            let length = obj.engGivenName.length
            if (length < 11) {
                firstName = obj.engGivenName.substring(0, 1) + new Array(length - 1).fill('*').join('')
            } else {
                firstName = obj.engGivenName.substring(0, 1) + new Array(10).fill('*').join('')
            }
        }
        name = firstName + ' ' + obj.engSurname
    }
    // } else {
    //   if (obj && obj.chnName) {
    //     name = obj.chnName.split('').map((char, idx) => {
    //       if (idx == 0) {
    //           return char
    //       }
    //       return '*'
    //     }).join('')
    //   }
    // }

    if (needRealName) {
        if (!name && obj && obj.realName && obj.realName.trim()) {
            name = obj.realName
        }

        if (!name && obj && obj.email && obj.email.trim()) {
            name = obj.email
        }
    } else {
        if (!name && obj && obj.email && obj.email.trim()) {
            name = obj.email
        }

        if (!name && obj && obj.phoneNo && obj.phoneNo.trim()) {
            let num = obj.phoneNo.toString()
            name = num.substr(0, 2) + '****' + num.substr(-2)
        }
    }

    return name
}

export function formatHKID(hkid) {
    if (hkid) {
        return hkid.substring(0, 5) + '***'
    }
    return ''
}

// 千分位符号
export function thousands(num) {
    if (!num) return 0
    var str = num.toString()
    var reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
    return str.replace(reg, "$1,")
}

// 金额格式化
export function formatMoney(money) {
    let res = ''
    let num = Number(money)
    if (isNaN(num)) {
        res = 0
    } else {
        let handleNum = parseFloat(num)
        let isToFloat = handleNum.toString().includes('.') && handleNum.toString().split('.')[1].length > 2
        if (isToFloat) {
            res = handleNum.toFixed(2)
        } else {
            res = handleNum
        }
    }
    return thousands(res)
}

export function formatMoneyNoQianFen(money) {
    let m = Number(money)
    if (!isNaN(m)) {
        return m.toFixed(2)
    }
    return 0
}

export function getUUID() {
    let str = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let res = '';
    for (let i = 0; i < 32; i++) {
        res += str[Math.floor(Math.random() * 36)];
    }
    return res
}

export const Encryption = {
    name(lang, obj) {
        let encryptionNameText
        // 英文情况下
        if (lang.indexOf('en') > -1) {
            if (obj && obj.engGivenName && obj.engSurname) {
                const nameArr = obj.engSurname.split(' ');
                if (nameArr.length == 1) {
                    encryptionNameText = [obj.engGivenName, '*'].join(' ')
                } else {
                    const encryptionLen = nameArr.length - 1;
                    encryptionNameText = [obj.engGivenName, (Array(encryptionLen).fill('*').join('')), nameArr[nameArr.length - 1]].join(' ')
                }
                return encryptionNameText
            }
        } else {
            if (obj && obj.chnName) {
                const nameArr = obj.chnName.split('');
                if (nameArr.length < 2) {
                    encryptionNameText = nameArr[0]
                } else if (nameArr.length == 2) {
                    encryptionNameText = [nameArr[0], '*'].join(' ')
                } else {
                    const encryptionLen = nameArr.length - 2;
                    encryptionNameText = [nameArr[0], (Array(encryptionLen).fill('*').join('')), nameArr[nameArr.length - 1]].join('')
                }
                return encryptionNameText
            }
        }
        return '/'
    }, guardianName(name) {
        let encryptionNameText
        if (name) {
            const nameArr = name.split('');
            if (nameArr.length < 2) {
                encryptionNameText = nameArr[0]
            } else if (nameArr.length == 2) {
                encryptionNameText = [nameArr[0], '*'].join(' ')
            } else {
                const encryptionLen = nameArr.length - 2;
                encryptionNameText = [nameArr[0], (Array(encryptionLen).fill('*').join('')), nameArr[nameArr.length - 1]].join('')
            }
            return encryptionNameText
        }
        return '/'
    }, phone(phoneNo) {
        if (phoneNo) {
            const phoneText = `${phoneNo}`.trim()
            const phoneLen = phoneText.length
            const headLen = (phoneLen - (phoneLen % 2)) / 2 - 2
            const endLen = (phoneLen + (phoneLen % 2)) / 2 - 2
            const phoneReg = new RegExp(`^(\\d{${headLen}})\\d{4}(\\d{${endLen}})$`)
            const encryptionPhone = phoneText.replace(phoneReg, '$1****$2')
            return encryptionPhone
        }
        return '/'
    },

    email(emailT) {
        if (emailT) {
            const emailText = `${emailT}`.trim()
            const emailArr = emailText.split('@')
            const fillLen = emailArr[0].substring(1).length
            const headEmail = emailArr[0].substring(0, 1) + Array(fillLen).fill("*").join('')
            const encryptionEmailArr = [...emailArr]
            encryptionEmailArr[0] = headEmail
            const encryptionEmail = encryptionEmailArr.join('@')
            return encryptionEmail
        }
        return '/'
    }, IDCard(id) {
        if (id) {
            const idText = id.trim()
            const fillLen = idText.substring(1).length
            const encryptionid = idText.substring(0, 1) + Array(fillLen).fill("*").join('')
            return encryptionid
        }
        return '/'
    }
}

// 防抖
let timeout = null

export function debounce(func, wait = 500, immediate = false) {
    // 清除定时器
    if (timeout !== null) clearTimeout(timeout)
    // 立即执行，此类情况一般用不到
    if (immediate) {
        const callNow = !timeout
        timeout = setTimeout(() => {
            timeout = null
        }, wait)
        if (callNow) typeof func === 'function' && func()
    } else {
        // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
        timeout = setTimeout(() => {
            typeof func === 'function' && func()
        }, wait)
    }
}


// 计算年龄的方法
export function calculateAge(birth) {
    let today = new Date()
    let birthDate = new Date(birth)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}

export function logParams(params, groupName) {
    if (groupName) {
        console.group(groupName)
    }
    if (params) {
        if (typeof (params) === 'object') {
            console.log('object params', JSON.parse(JSON.stringify(params)))
        } else {
            console.log('string params', params)
        }
    } else {
        console.log('params', params)
    }
    if (groupName) {
        console.groupEnd()
    }
}


/*
* a ={b:{c:1}}
* log a['b']['c']
* 当 b,c 均为变量时
* 无法直接通过 [][]的方式取值
*
* 调用方法如下
*   deepGetVal(['b','c'],a)
*   return  1
* */

export function deepGetVal(deepArr = [], obj = {}) {
    const [firstStr] = deepArr
    if (deepArr.length > 1) {
        const _deepArr = JSON.parse(JSON.stringify(deepArr))
        _deepArr.splice(0, 1)
        const _obj = obj[firstStr] || {}
        if (_obj) {
            return deepGetVal(_deepArr, _obj)
        } else {
            return undefined
        }
    } else {
        return obj[firstStr]
    }
}

/*
* 背景同上
*
* 调用方法如下
*   deepGetVal2('b,c',a)
*   return  1
* */
export function deepGetVal2(deepStr, obj = {}) {
    const keysArrStr = deepStr.split('.')
    if (obj) {
        return deepGetVal(keysArrStr, obj)
    } else {
        return undefined
    }
}
