<template>
  <div class="notice-wrap">
    <div v-if="img && content" class="notice-content">
      <div class="icon">
        <img :src="img" referrerpolicy="no-referrer" />
      </div>
      <div class="title marquee content">
        <div style="width: 100%;" :class="{'marquee-txt': isScroll}" ref="marqueeRef">{{ content }}</div>
      </div>
    </div>
    <div v-else class="notice-content">
      <div class="marquee" style="width: 100%;">
        <div class="title" :class="{'marquee-txt': isScroll, 'jucontent-center': !isScroll}" ref="marqueeRef" v-html="isExistUser?(getUserName()+timeLeft):timeLeft"></div>
      </div>
    </div>
    
    <Popup showTime="10" v-if="isOpen" @closePop="closePop">{{ errMessage }}</Popup>
    <Popup showTime="10" v-if="showPayTimeOut" @closePop="closePayTimeOutPop">{{ $t('payTimeOut') }}</Popup>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import moment from 'moment'
import Popup from '@/views/common/popup'
import useEndTime from '@/hooks/useEndTime'
import { useRouter, useRoute } from 'vue-router'
import { cancelBooking, logout, cancelBookingForProgramme } from '@/http/api'
import { onMounted, ref, watch } from '@vue/runtime-core'
import { formatName } from '@/util/util'
export default {
  name: 'notice-component',
  components: {
    Popup
  },
  props: {
    message: {
      type: String,
      default: 'payCountdown'
    },
    deadline: {
      type: [Number, String],
      default: 900 // 默认倒计时900秒
    },
    img: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const isOpen = ref(false)
    const errMessage = ref('')
    const route = useRoute()
    const router = useRouter()
    let curUsername = ref('')
    let isExistUser = ref(false)
    let isScroll = ref(false)
    const marqueeRef = ref(null)
    const distance = ref('0')
    onMounted(()=>{
      setScroll()
    })
    const { applicationId } = route.query
    const { t } = useI18n();
    // 支付超时
    const showPayTimeOut = ref(false)
    // 关闭支付超时弹框
    const closePayTimeOutPop = () => {
      showPayTimeOut.value = false
      if (
          route.name === 'confirmBooking' ||
          route.name === 'facilityBookingDetail'
        ) {
          cancelBooking(applicationId).then((res) => {
            // if (res && res.code && res.code != 0) {
            //   isOpen.value = true
            //   errMessage.value = res.message || t('serverErr')
            //   return
            // }
          }).finally(() => {
            logoutAPI()
          })
        } else if (
          route.name == 'confirmProgrammeBooking' ||
          route.name == 'programmeBookingDetail'
        ) {
          let _params = {
            applicationId: applicationId
          }
          cancelBookingForProgramme(_params).then((res) => {
            // if (res && res.code && res.code != 0) {
            //   isOpen.value = true
            //   errMessage.value = res.message || t('serverErr')
            //   return
            // }
          }).finally(() => {
            logoutAPI()
          })
        }
    }
    console.log('route', route.name)
    const logoutAPI = () => {
      logout().then((res) => {
        if (res && res.code && res.code != 0) {
          isOpen.value = true
          errMessage.value = res.message || t('serverErr')
          return
        }
        localStorage.removeItem('token')
      }).finally(() => {
        router.push({
          name: 'home'
        })
      })
    }

    const setScroll = () => {
      if(marqueeRef.value){
        distance.value = (- marqueeRef.value.scrollWidth / marqueeRef.value.clientWidth * 100) + '%'
        if (marqueeRef.value.scrollWidth > marqueeRef.value.clientWidth) {
          isScroll.value = true
        } else {
          isScroll.value = false
        }
      }
    }

    if(sessionStorage.getItem("userinfo")) {
      let userInfo = JSON.parse(sessionStorage.getItem("userinfo"))
      curUsername.value = formatName(localStorage.getItem('language'), userInfo)
      isExistUser.value = true
    } else {
      curUsername.value = ''
      isExistUser.value = false
    }

    const getUserName = () => {
      return t('hi_username',{username: curUsername.value})
    }

    const params = {
      langKey: props.message,
      paramKey: 'num',
      seconds: props.deadline,
      type:'payment',
      isFormat: true, // 是否按照 时:分:秒 的格式输出时间
      // isDelay: true,
      endTimeEvent() {
        // 显示支付超时弹框
        showPayTimeOut.value = true
        console.log('time out!')
      }
    }

    const closePop = () => {
      isOpen.value = false
    }
    // console.log('传过来的数据----------------', props, props.faCode, props.venueId);
    let { timeLeft } = useEndTime(params)
    // 监听文案变化  实时判断是否需要滚动
    watch(timeLeft, () => {
      setScroll()
    })

    return {
      timeLeft,
      isOpen,
      errMessage,
      logoutAPI,
      closePop,
      getUserName,
      isScroll,
      isExistUser,
      showPayTimeOut,
      closePayTimeOutPop,
      marqueeRef,
      distance
    }
  },
  methods: {
    moment,
    onFinish() {
      this.$emit('finish')
    }
  }
}
</script>

<style lang="scss" scoped>
$pxtoRem: 100;

@function pxttRem($px) {
  @return calc($px / $pxtoRem) + rem;
}

.notice-wrap {
  font-family: 'Barlow';
  width: 100%;
  position: fixed;
  top: 0rem !important;
  z-index: 21;
  background: linear-gradient(73deg, #8F0044 11.91%, #BC003C 37.3%, #E20030 63.49%, #FF1636 88.09%);
  display: flex;
  height: pxttRem(70);
  line-height: pxttRem(70);
  justify-content: center;

  .notice-content{
    width: calc(100% - 1rem);
    margin-right:0.5rem;
    margin-left:0.5rem;
    display: flex;

    .content{
      width: calc(100% - 0.48rem - 0.3rem);
      margin-left: 0.3rem;
    }
  }

  .icon {
    img {
      width: pxttRem(48);
      height: pxttRem(48);
    }
  }

  .title {
    @include color-font('color3');
    text-align: center;
    font-size: pxttRem(24);
    display: flex;

    :deep span {
      font-size: 32px;
      padding: 0 8px;
      font-weight: 600;
    }
  }
}

.jucontent-center{
  justify-content: center;
}

/* 文字滚动 */
.marquee {
	box-sizing: border-box;
	word-break: break-all;
	white-space: nowrap;
	overflow: hidden;
}
.marquee-txt {
	display: inline-block;
	/* 从右至左开始滚动 */
	animation: marqueeTransform 16s linear infinite;
  &:hover {
    //鼠标悬停 停止横向滚动
    animation-play-state: paused;
  }
}

@keyframes marqueeTransform {
	0% {
		transform: translate(100%, 0);
	}

	100% {
		transform: translate(v-bind(distance), 0);
	}
}

</style>
