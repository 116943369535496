import { createStore } from 'vuex'
const store = {
    state: {
        facilityVenue: {}, // 选择场地页面选定的场地和运动信息
        userInfo: {}, // 用户信息
        venueInfo: {}, // 场馆信息
        apiLoadingNum: 0, // 当前是否有接口请求中 用于展示loading图标
        selectMenuType: '', // 当前选择的是cch还是csp
        headerBackgroundImage: undefined, // header背景图片
        currentLanguage: '', // 当前语言
        programmeNeedRealName: '' // 课程预订是否需要实名 
    },
    getters: {
        userInfo: state => state.userInfo,
        venueInfo: state => state.venueInfo,
        getUserId: state => {
            // console.log(state.userInfo);
            if (state.userInfo && state.userInfo.pid) {
                return state.userInfo.pid;
            }
            return '';
        },
        selectMenuType: state => state.selectMenuType,
        headerBackgroundImage: state => state.headerBackgroundImage,
        currentLanguage: state => state.currentLanguage,
        programmeNeedRealName: state => state.programmeNeedRealName
    },
    mutations: {
        setFacilityVenue(state, payload) {
            state.facilityVenue = payload;
        },
        setApiLoadingNum(state, num) {
            state.apiLoadingNum += num;
            if (state.apiLoadingNum < 0) {
                state.apiLoadingNum = 0;
            }
        },
        CURRENT_USER_INFO(state, payload) {         
            Object.keys(payload).forEach(item => {
                state.userInfo[item] = payload[item];
            })
            // state.userInfo = payload;
        },
        CURRENT_VENUE_INFO(state, payload) {
            state.venueInfo = payload;
        },
        setMenuType(state, payload) {
            state.selectMenuType = payload;
        },
        setHeaderBackgroundImage(state, payload) {
            state.headerBackgroundImage = payload;
        },
        setCurrentLanguage(state, payload) {
            state.currentLanguage = payload;
        },
        setProgrammeNeedRealName(state, payload) {
            state.programmeNeedRealName = payload;
        }
    }
}

export default new createStore(store);