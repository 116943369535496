/*
 * @Descripttion:
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-02-08 22:57:20
 * @LastEditors: licuixia
 * @LastEditTime: 2023-04-18 16:47:44
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import "@/util/flexible.js"
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { vant } from "../vant.config";
import "vant/lib/index.css";
import "@/assets/style/font/font.css";
import "@/assets/style/_base.scss";
import "@/assets/style/_antd.scss";
import * as Icons from '@ant-design/icons-vue';

import Bus from "@/util/bus.js";
import { compatibleEvent } from "@/micro/compatible-event";

import "@/assets/style/entry-card.scss" // cch,csp 入口卡片样式


// createApp(App).mount('#app')
const app = createApp(App);
// app.config.unwrapInjectedRef = true

compatibleEvent();
// 全局使用图标
const icons = Icons;
for (const i in icons) {
    app.component(i, icons[i]);
}
// 路由
app.use(i18n).use(router).use(store).use(Antd);
vant(app);

app.config.globalProperties.$bus = Bus;
app.mount("#app");
