/**
 * 获取Token值
 * @returns {string}
 */
import { useStore } from 'vuex'
export function getToken() {
  return localStorage.getItem('Token')
}

/**
 * 设置Token值
 * @param token
 */
export function setToken(token) {
  localStorage.setItem("Token", token)
}

export function removeToken() {
  localStorage.removeItem("Token")
}

export function setLanguage(language, store) {
  let tempStore = useStore();
  if (!tempStore) {
    tempStore = store
  }
  tempStore && tempStore.commit('setCurrentLanguage', language);
  localStorage.setItem("language", language)
}

export function getLanguage() {
  return localStorage.getItem('language')
}

export function setParam(key, value) {
  localStorage.setItem(key, value)
}

export function getParam(key) {
  return localStorage.getItem(key)
}

export function removeParam(key) {
  localStorage.removeItem(key)
}
