/*
 * @Descripttion:
 * @Version: 1.0
 * @Author: licuixia
 * @Date: 2023-06-02 13:59:52
 * @LastEditors: licuixia
 * @LastEditTime: 2024-11-13 14:34:34
 */
let env = {
  baseURL: '',  // 下面配置打exe包用的。
  // serverUrl: 'https://kiosk.vbsktsp.local',   //生产大厅
  //  serverUrl: 'https://vbs-admin-dev.xports.cn',   //开发环境
  //  serverUrl: 'https://kiosk.ktsp-vbs.com:34051' // 新生产环境2023.12.10更新
  // serverUrl: 'https://uat-kiosk.ktsp-vbs.com:34053',   //UAT环境
  // serverUrl: 'https://test-vbs.ktsp-vbs.com',  //(测试环境)

};
export default env;
