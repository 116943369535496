<template>
  <div>
    <div :class="['header page-header', {'z-index-0': zIndex}, {'top70': showNotice}]"
         id="pageHeader"
         v-show="showHeader || !hideHeader">
      <img v-if="$store.state.headerBackgroundImage" :src="$store.state.headerBackgroundImage" class="header-bg"/>
      <img v-else-if="$store.state.selectMenuType === 'csp'" src="@/assets/image/menu/ic_programme.png" class="header-bg"/>
      <img v-else src="@/assets/image/menu/ic_facility.png" class="header-bg"/>
      <!-- <img src="@/assets/image/ktsp-logo.svg" alt="kiosk logo" class="home-logo"/> -->
      <page-head class="page-head-style" :title="title" :hide-home="hideHome">
        <template #content>
          <slot></slot>
        </template>
      </page-head>
    </div>
    <div v-show="!showHeader &&hideHeader" :class="{'height70': showNotice}">
    </div>
  </div>
</template>

<script>
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {ref, watch, inject} from "@vue/runtime-core";
import useFooter from '@/hooks/useFooter';
import pageHead from '@/views/common/pageHead'

export default {
  name: 'header-page',
  components: {
    pageHead,
  },
  props: {
    showNotice: {
      type: Boolean
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    hideHome: { // 隐藏home按钮
      type: Boolean,
      default: false
    }
  },
  setup() {
    const router = useRouter();
    const {locale} = useI18n();
    // let pageName = ref("");
    let isGreyBg = ref(false);
    let isSpecialMode = inject('isSpecialMode');
    let zIndex = inject('headerZIndex');
    let {hideHeader} = useFooter(isSpecialMode);

    // 监听路由变化，修改页面名称
    // watch(
    //   () => router.currentRoute.value.name,
    //   (n) => {
    //     pageName.value = t(n);
    //   }
    // );

    // 监听语言变化，修改页面名称
    watch(
        () => [locale, router.currentRoute.value.name],
        () => {
          // pageName.value = t(router.currentRoute.value.name);
          isGreyBg.value = router.currentRoute.value.meta.isGrey ? true : false;
        }
    );


    // 页面回退
    const goBack = () => {
      // 有些页面不是回退到上一页 需要额外判断
      if (router.currentRoute.value.name === "order") {
        router.push("/");
      } else {
        router.back();
      }
    };

    // 一键换肤
    const changeSkin = () => {
      document.documentElement.setAttribute("data-theme", "blue");
    };

    return {
      hideHeader,
      zIndex,
      isGreyBg,
      changeSkin,
      goBack,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  width: 100%;
  top: 0;
  @include color-bg-primary('color3');
  z-index: 20;

  &.z-index-0 {
    z-index: 0;
  }

  .left {
    display: flex;
    height: 100%;
    align-items: center;

    .icon {
      height: 0.4rem;
      width: 0.4rem;
      background: #fff;
      margin-right: 0.12rem;
      border-radius: 50%;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 0.16rem;
        width: 0.16rem;
        @include header-arrow("color-header-bg");
        transform: rotate(45deg);
        left: 0.15rem;
        top: 0.12rem;
      }
    }

    .title {
      font-size: 0.32rem;
      font-weight: bold;
    }
  }

  .right {
    width: 0.48rem;
    height: 0.48rem;
    background: #fff;
  }

  .header-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .page-head-style {
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%);
    //url(<path-to-image>)  lightgray 50% / cover no-repeat;
    z-index: 1;
    position: relative;
  }

  .page-head-grey-style {
    @include color-bg-primary('color-nav-grey-bg');
  }

  .home-logo {
    width: 1.6rem;
    height: 0.72rem;
    position: absolute;
    top: 0.45rem;
    left: 0.4rem;
    z-index: 1;
  }
}

.height70{
  height: 70px;
  overflow: hidden;
}

.top70{
  top: 70px !important;
}

.isSpecialMode-mask {
  height: 30%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
</style>
