<template>
    <div class="mask">
        <div class="popup-wrap">
            <!-- <div :class="[popType]"></div> -->
            <img src="@/assets/image/ic_warnning.png" v-if="popType == 'warn'" class="warn" />
            <span v-else-if="popType === 'success'"></span>
            <img src="@/assets/image/ic_error.png" v-else class="error" />
            <div class="warn-info">
                <slot />
            </div>
            <div class="confirmBtns" v-if="type == 'confirm'">
                <div class="cancel" @click="closePop">{{ $t('no') }}</div>
                <div class="ok" @click="confirmPop">{{ $t('yes') }}</div>
            </div>
            <div v-else class="ok" @click="closePop">{{ $t('ok') }}</div>
            <div class="close-tip" v-html="timeLeft"></div>
        </div>
    </div>
</template>

<script>
import useEndTime from '@/hooks/useEndTime';
import { watch } from '@vue/runtime-core';
export default {
    name: 'page-popup',
    props: {
        type: {
            type: String,
            default: '',
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        popType: {
            type: String,
            default: 'warn',
        },
        showTime: {
            type: [Number, String],
            default: 30,
        },
    },
    emits: ['closePop', 'confirm'],
    setup(props, context) {
        const params = {
            langKey: "timeLeft",
            paramKey: "leftTime",
            seconds: props.showTime,
            endTimeEvent() {
                // router.push({ name: "menu" });
                context.emit('closePop');
            },
        };
        let { timeLeft, cancel } = useEndTime(params);
        const confirmPop = () => {
            cancel();
            context.emit('confirm');
        }
        const closePop = () => {
            cancel();
            context.emit('closePop', false);
        }
        watch(() => props.isOpen, (val) => {
            params.seconds = props.showTime;
        })

        return {
            closePop,
            timeLeft,
            confirmPop
        }
    }
}
</script>
<style lang="scss">
.special-mode .popup-wrap {
    // top: auto !important;
    // bottom: 20% !important;
}
</style>
<style lang="scss" scoped>
.mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);

    .popup-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8.4rem;
        // height: 7rem;
        border-radius: 0.3rem;
        transform: translate(-50%, -50%);
        @include color-bg-primary('color3');
        display: flex;
        flex-direction: column;
        align-items: center;

        .warn,
        .error {
            border-radius: 50%;
            margin-top: 1rem;
            width: 1.3rem;
            height: 1.3rem;
            box-shadow: 0 0 0.12rem 0.16rem rgba(252, 152, 0, 0.1);
            // background: url(@/assets/image/ic_warnning.png) no-repeat 0 0 / 1.6rem 1.6rem;
        }

        .error {
            // background-image: url(@/assets/image/ic_error.png);
            box-shadow: 0 0 0.12rem 0.16rem rgba(241, 0, 51, 0.1);
        }

        .warn-info {
            @include text-color('color-text-dark');
            font-size: 0.48rem;
            font-weight: bold;
            line-height: 0.64rem;
            margin: 0.6rem 0 0.6rem;
            padding: 0 1rem;
            text-align: center;
            white-space: pre-line;
            width: 100%;
            word-break: break-word;
        }

        .ok {
            width: 400px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            background: var(--KT-Gradient-Red-01, linear-gradient(73deg, #8F0044 11.91%, #BC003C 37.3%, #E20030 63.49%, #FF1636 88.09%));
            border-radius: 40px;
            font-size: 32px;
            font-weight: 600;
            @include text-color('color3');
        }

        .cancel {
            border: 0.02rem solid #EC0032;
            height: 1.2rem;
            line-height: 1.2rem;
            text-align: center;
            border-radius: 0.15rem 0.45rem 0.15rem 0.15rem;
            font-size: 0.48rem;
            font-weight: bold;
        }

        .confirmBtns {
            display: flex;

            .ok {
                width: 2.7rem;
                margin: 0 .2rem;
            }

            .cancel {
                width: 2.7rem;
                margin: 0 .2rem;
                color: #EC0032;
            }
        }


        .close-tip {
            margin-top: 0.4rem;
            margin-bottom: 0.3rem;
            opacity: 0.4;
            height: 0.5rem;
            line-height: 0.5rem;
            font-size: 0.24rem;
            @include text-color('color-text-dark');

            :deep span {
                font-size: 0.4rem;
            }
        }
    }
}
</style>