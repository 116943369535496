/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: licuixia
 * @Date: 2023-02-08 22:57:20
 * @LastEditors: licuixia
 * @LastEditTime: 2023-10-30 20:09:22
 */
import { onUnmounted,onMounted, ref } from "vue";
import { useI18n } from 'vue-i18n';
export default obj => {
    const { t } = useI18n();
    const seconds = ref(obj.seconds);
    const halfSeconds = Math.ceil(parseInt(localStorage.getItem('configlogoutTime'))/2)
    const timeLeft = ref('');
    timeLeft.value = t(obj.langKey, {[obj.paramKey]: seconds.value });
    let interval = null;
    if (!obj.isDelay) {
        start();
    }
    onUnmounted(() => {
      if(obj.type == 'commonNotice'){  
        if(seconds.value) {
          localStorage.setItem('logoutTime', seconds.value)
        }
      }
      // 页面跳转清除定时器
      cancel();
    });
    onMounted(()=>{
      if(obj.type == 'payment'||obj.type == 'commonNotice') {   //支付页面不需要监听鼠标事件
        return
      }
      let tmpSeconds = ref(obj.seconds);
      window.addEventListener("mousemove", () => {
        seconds.value = tmpSeconds.value;
      })
      window.addEventListener("touchmove", () => {
        seconds.value = tmpSeconds.value;
      })
      window.addEventListener("touchstart", () => {
        seconds.value = tmpSeconds.value;
      })
      window.addEventListener("click", () => {
        seconds.value = tmpSeconds.value;
      })
      window.addEventListener("wheel", () => {
        seconds.value = tmpSeconds.value;
      })
      window.addEventListener("keydown", () => {
        seconds.value = tmpSeconds.value;
      })
    })

    function start() {
        seconds.value = obj.seconds;
        let time = obj.isFormat ? timeFormat(seconds.value) : seconds.value;
        timeLeft.value = t(obj.langKey, {[obj.paramKey]: time});
        clearInterval(interval);
        interval = setInterval(() => {
            seconds.value -= 1; 
            let tmpTime = timeFormat(seconds.value)
            if(seconds.value == halfSeconds) {
              obj.reminderEvent&&obj.reminderEvent(tmpTime)
            }
            time = obj.isFormat ? tmpTime : seconds.value;
            timeLeft.value = t(obj.langKey, {[obj.paramKey]: time});
            if (seconds.value <= 0) {
                clearInterval(interval);
                obj.endTimeEvent();
            }
        }, 1000);
    }

    // seconds 秒数
    function timeFormat(seconds) {
        if (Number(seconds) >= 0) {
            let h = Math.floor(seconds / 3600);
            let left = seconds % 3600;
            let m = Math.floor(left / 60);
            let s = left % 60;
            let res = '';
            if (h != 0) {
                res += h > 9 ? h + ':' : '0' + h + ':'; 
            }
            // res += m > 9 ? m + ':' : '0' + m + ':';
            res += m + ':';
            res += s > 9 ? s : '0' + s;
            return res;
        }
    }

    function cancel() {
        clearInterval(interval);
    }
    
    return { timeLeft, cancel, start };
}