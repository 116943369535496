import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

// const Home = { template: '<div>Home</div>' }
// const About = { template: '<div>About</div>' }

// 每个路由都需要映射到一个组件。
const routes = [
  {
    path: "/",
    redirect: '/home',
    // redirect: '/login',
    // redirect: '/print',
    // redirect: '/paymentSuccess',
    // redirect: '/payway'
  },
  {
    path: "/selectCourt",
    name: "selectCourt",
    component: () => import("@/views/facility/selectCourt"),
  },
  {
    path: "/octopus",
    name: "octopus",
    component: () => import("@/views/common/octopus"),
  },
  {
    path: "/alipay",
    name: "alipay",
    component: () => import("@/views/common/alipay"),
  },
  {
    path: "/creidtCard",
    name: "creidtCard",
    component: () => import("@/views/common/creidtCard"),
  },
  {
    path: "/paymentFailed",
    name: "paymentFailed",
    component: () => import("@/views/common/PymentFailed"),
  },
  {
    path: "/paymentSuccess",
    name: "paymentSuccess",
    component: () => import("@/views/common/PaymentSuccess"),
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    component: () => import("@/views/common/paySuccess"),
    meta: {
      returnText: "programmeDetail_title",
    }
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/homepage/home"),
  },
  {
    path: "/checkIn",
    name: "checkIn",
    component: () => import("@/views/checkIn/checkIn"),
    meta: {
      isHideHome: true,
      returnText: "checkIn"
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/homepage/login"),
    meta: {
      returnText: "loginTip",
    }
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("@/views/homepage/menu"),
  },

  {
    path: '/facilityDeclaration',
    name: 'facilityDeclaration',
    component: () => import("@/views/decration/facilityDeclaration")
  },
  {
    path: "/payway",
    name: "payway",
    component: () => import("@/views/pay/PayWay"),
  },
  {
    path: "/facility",
    name: "facility",
    component: () => import("@/views/booking/facility"),
  },
  {
    path: "/selectSport",
    name: "selectSport",
    component: () => import("@/views/booking/selectSport"),
    meta: {
      returnText: 'pickSport',
      beforeGoHomeCall: true,
      beforeBackCall: true,
    }
  },
  {
    path: "/selectDateTime",
    name: "selectDateTime",
    component: () => import("@/views/booking/selectDateTime"),
    meta: {
      beforeGoHomeCall: true,
      beforeBackCall: true,
    }
  },
  {
    path: "/facilityBookingDetail",
    name: "facilityBookingDetail",
    component: () => import("@/views/booking/facilityBookingDetail"),
    meta: {
      returnText: "programmeDetail_title",
      returnPage: "selectDateTime",
      isNeedLogout: true,
      isGrey: true,
      beforeGoHomeCall: true,
      beforeBackCall: true,
      isShowExit: true
    }
  },
  {
    path: "/confirmBooking",
    name: "confirmBooking",
    component: () => import("@/views/booking/confirmBooking"),
    meta: {
      returnText: "confirmBooking",
      isNeedLogout: true,
      isGrey: true,
      beforeGoHomeCall: true,
      beforeBackCall: true,
      isShowExit: true
    }
  },
  {
    path: "/selectFriend",
    name: "selectFriend",
    component: () => import("@/views/facility/SelectFriend"),
  },
  {
    path: "/ProgrammeDetail",
    name: "ProgrammeDetail",
    component: () => import("@/views/booking/programmeDetail"),
    meta: {
      returnText: "programme_title",
    }
  },
  {
    path: "/programmeBookingDetail",
    name: "programmeBookingDetail",
    component: () => import("@/views/booking/programmeBookingDetail"),
    meta: {
      returnText: "programmeDetail_title",
      returnPage: "ProgrammeDetail",
      isNeedLogout: true,
      isGrey: true,
      beforeBackCall: true,
      beforeGoHomeCall: true,
      isShowExit: true,
    }
  },
  {
    path: "/confirmProgrammeBooking",
    name: "confirmProgrammeBooking",
    component: () => import("@/views/booking/confirmProgrammeBooking"),
    meta: {
      returnText: "confirmBooking",
      isNeedLogout: true,
      isGrey: true,
      isShowExit: true,
    }
  },
  {
    path: "/mobelPaySuccess",
    name: "mobelPaySuccess",
    component: () => import("@/views/common/mobelPaySuccess"),
  },
  {
    path: "/selectCoupon",
    name: "selectCoupon",
    component: () => import("@/views/booking/selectCoupon"),
  },
  {
    path: "/programme",
    name: "programme",
    component: () => import("@/views/programme/programme"),
    meta: {
      returnText: 'programme',
      isNeedSearch: false,
      beforeBackCall: true
    }
  },
  {
    path: "/programmeParQ",
    name: "programmeParQ",
    component: () => import("@/views/parQ/programmeParQ"),
  },
  {
    path: "/print",
    name: "print",
    component: () => import("@/views/common/print"),
  },
  {
    path: "/printFailed",
    name: "printFailed",
    component: () => import("@/views/common/printFailed"),
  },
  {
    path: "/printSuccess",
    name: "printSuccess",
    component: () => import("@/views/common/printSuccess"),
  },
  {
    path: "/more",
    name: "more",
    component: () => import("@/views/booking/more"),
  },
  {
    path: "/facilityDetail",
    name: "facilityDetail",
    component: () => import("@/views/booking/facilityDetail"),
  },
  {
    path: "/virtualQueue",
    name: "virtualQueue",
    component: () => import("@/views/facility/virtualQueue"),
  },
  {
    path: "/programmeParQ70",
    name: "programmeParQ70",
    component: () => import("@/views/parQ/programmeParQ70"),
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("@/views/register/index"),
  // },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register/registerMethods"),
    redirect: '/register/registerAction',
    children: [
      {
        path: 'registerAction',
        name: 'registerAction',
        component: () => import("@/views/register/action"),
      },
      {
        path: 'registerHKID',
        name: 'registerHKID',
        component: () => import("@/views/register/registerHKID"),
        meta: {
          returnText: 'registerByEmail',
        }
      },
      {
        path: 'scanQrCode',
        name: 'scanQrCode',
        component: () => import("@/views/register/scanQrCode"),
        meta: {
          returnText: 'scanQrCode',
        }
      }
    ],
    meta: {
      returnText: 'registerByHKID',
      isHideHome: true,
    }
  },
  {
    path: "/registerSuccess",
    name: "registerSuccess",
    component: () => import("@/views/register/regSuccess"),
  },
  {
    path: "/realName",
    name: "realName",
    component: () => import("@/views/realName"),
    meta: {
      returnText: 'realNameTop',
      isHideHome: true,
      beforeBackCall: true
    }
  },
  {
    path: "/ballotingHistory",
    name: "ballotingHistory",
    component: () => import("@/views/ballot/ballotingHistory"),
    meta: {
      returnText: "ballotingHistory",
      returnPage: "ProgrammeDetail"
    }
  },
  {
    path: "/ballotingResult",
    name: "ballotingResult",
    component: () => import("@/views/ballot/ballotingResult"),
  },
  {
    path: "/confirmProgrammeBallot",
    name: "confirmProgrammeBallot",
    component: () => import("@/views/ballot/confirmProgrammeBallot"),
    meta: {
      returnText: "programmeBallot",
      isNeedLogout: true,
      beforeBackCall: true,
      beforeGoHomeCall: true
    }
  },
  {
    path: "/ballotSelect",
    name: "ballotSelect",
    component: () => import("@/views/booking/ballotSelect"),
    meta: {
      // returnText: "ballotSelect",
      returnPage: "selectDateTime",
    }
  },
  {
    path: "/confirmBalloting",
    name: "confirmBalloting",
    component: () => import("@/views/booking/confirmBalloting"),
    meta: {
      returnText: "facilityBallot",
      beforeBackCall: true,
      beforeGoHomeCall: true
    }
  },
  {
    //抽签成功
    path: "/ballotSuccess",
    name: "ballotSuccess",
    component: () => import("@/views/booking/ballotSuccess"),
    meta: {
      isNeedLogout: true,
      isHideGoBack: true,
    }
  },
  {
    path: "/kiosk/ssoJump",
    name: "SsoJump",
    component: () => import("@/views/homepage/ssoJump")
  }
  // {
  //   path: "/registerAction",
  //   name: "registerAction",
  //   component: () => import("@/views/register/action"),
  // },
  // {
  //   path: "/registerAction",
  //   name: "registerAction",
  //   component: () => import("@/views/register/action"),
  // }
];

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里暂时保持简单
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  //history: createWebHistory(), // history模式
  routes,
});

export default router;
