import axios from "axios";
import store from "@/store";
import router from '@/router/index'
import {getLanguage, getToken, removeParam, setToken} from "@/util/authentication";

import jsonBig from "json-bigint";

import env from '@/config/env.js';

if (process.env.NODE_ENV === 'production') {
    env.baseURL = env.serverUrl
}
// 接口地址
axios.defaults.baseURL = env.baseURL;
//post请求头
axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded;charset=UTF-8";
//设置超时
axios.defaults.timeout = 30000;

axios.defaults.transformResponse = [
    function (data, headers) {
        // 解决精度丢失问题
        // 如果是文件流就不进行转换
        if (
            headers["Content-Type"] === "application/json" ||
            headers["content-type"] === "application/json"
        ) {
            try {
                const json = jsonBig({
                    storeAsString: true,
                });
                // 如果转换成功则返回转换的数据结果
                return JSON.parse(json.stringify(json.parse(data)));
            } catch (err) {
                // 如果转换失败，则包装为统一数据格式并返回
                return JSON.parse(data);
            }
        } else {
            return data;
        }
    },
];

axios.interceptors.request.use(
    (config) => {
        // do something before request is sent
        config.headers["Accept"] = "application/json";
        config.headers["Accept-Language"] = getLanguage();
        config.headers["Content-Type"] = config.headerObj && config.headerObj["Content-Type"] || "application/json; charset=utf-8";
        config.headers["channel"] = "3S";
        config.headers["Access-Control-Allow-Origin"] = "*";
        if (getToken() && config.url.indexOf('3s/login') == -1) {
            config.headers["Authorization"] = "Bearer " + getToken();
        }

        if (config.timeout > 30 * 1000) {
            console.group('接口地址:', config.url)
            console.log('接口入参', config.data)
            console.log('接口headers', config.headers)
            console.log('config', config)
            console.groupEnd()
        }
        return config;
    },
    (error) => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        if (response?.headers && response?.headers?.access_token) {
            setToken(response.headers.access_token);
        }

        if (response.timeout > 30 * 1000) {
            console.group('接口地址:', response.config.url)
            console.log('接口状态', response.status)
            console.log('接口反参', response.data)
            console.log('response', response)
            console.groupEnd()
        }
        const res = response.data;


        if (response.status == 401 || response.status == 404 || response.status == 504) {
            removeParam("accessToken")
            removeParam("refreshToken")
            removeParam("username")
            removeParam("pid")
            removeParam("loginType")
            removeParam("Token")
            router.push('home');
            // window.electronEvent.send("b-home-event", "")
        }
        return Promise.resolve(res)
    },
    (error) => {
        // /t300/toPay
        let payReg = new RegExp('/t300/toPay');
        if (payReg.test(error?.config?.url) && !window.electronApi) {
            console.log('T300 not fount', '在非桌面环境')
            return
        }
        if (error.response && (error.response.status == 401 || error.response.status == 404 || error.response.status == 504)) {
            removeParam("accessToken")
            removeParam("refreshToken")
            removeParam("username")
            removeParam("pid")
            removeParam("loginType")
            removeParam("Token")
            router.push('home');
            // window.electronEvent.send("b-home-event", "")
        }
    }
);

const post = (url, data, isShowLoading = true, config) => {
    // 调用接口时是否显示loading
    if (isShowLoading) {
        store.commit('setApiLoadingNum', 1);
    }
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url,
            data: data,
            ...config
        })
            .then((res) => {
                // res = JSON.parse(res);
                resolve(res && res.data && res.code === '0' ? res.data : res);
            })
            .catch((err) => {
                reject(err);
            }).finally(() => {
            // 接口调用完毕隐藏loading图标
            if (isShowLoading) {
                store.commit('setApiLoadingNum', -1);
            }
        });
    });
};
const deleteA = (url, data, isShowLoading = true) => {
    // 调用接口时是否显示loading
    if (isShowLoading) {
        store.commit('setApiLoadingNum', 1);
    }
    return new Promise((resolve, reject) => {
        axios({
            method: "delete",
            url,
            data: data,
        })
            .then((res) => {
                resolve(res && res.data || res);
            })
            .catch((err) => {
                reject(err);
            })
            .finally(() => {
                // 接口调用完毕隐藏loading图标
                if (isShowLoading) {
                    store.commit('setApiLoadingNum', -1);
                }
            })
    });
};

const get = (url, data, isShowLoading = true) => {
    // 调用接口时是否显示loading
    if (isShowLoading) {
        store.commit('setApiLoadingNum', 1);
    }
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url,
            params: data,
        })
            .then((res) => {
                // res = JSON.parse(res);
                resolve(res && res.data || res);
            })
            .catch((err) => {
                reject(err);
            })
            .finally(() => {
                // 接口调用完毕隐藏loading图标
                if (isShowLoading) {
                    store.commit('setApiLoadingNum', -1);
                }
            });
    });
};

const put = (url, data, isShowLoading = true) => {
    // 调用接口时是否显示loading
    if (isShowLoading) {
        store.commit('setApiLoadingNum', 1);
    }
    return new Promise((resolve, reject) => {
        axios({
            method: "put",
            url,
            data: data,
        })
            .then((res) => {
                // res = JSON.parse(res);
                resolve(res && res.data || res);
            })
            .catch((err) => {
                reject(err);
            })
            .finally(() => {
                // 接口调用完毕隐藏loading图标
                if (isShowLoading) {
                    store.commit('setApiLoadingNum', -1);
                }
            });
    });
}

// 用于唤醒kiosk 自助机灯带
const requestLocalhost = (url, data) => {
    let baseUrl = axios.defaults.baseURL;
    // 接口地址
    // axios.defaults.baseURL = 'http://127.0.0.1:12323';
    if (process.env.NODE_ENV === 'production') {
        axios.defaults.baseURL = 'http://127.0.0.1:12323';
    }
    axios({
        method: "post",
        url,
        data: data,
    }).then(res => {
        console.log(res)
    })
        .catch(err => {
            console.log('error------>' + err)
        })
    axios.defaults.baseURL = baseUrl
}

export {post, get, deleteA, put, requestLocalhost};
